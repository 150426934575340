import React from 'react';
import css from '../Dashboard/Dashboard.module.scss';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext } from '../Session';

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <main className={css.main}>
        <h1>Account: {authUser.email}</h1>
        <PasswordChangeForm />
      </main>
    )}
  </AuthUserContext.Consumer>
);

export default AccountPage;