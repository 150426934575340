import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import css from './Popup.module.scss';

class MPopup extends Component {
  _isMounted = false;
  state = { show: true };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleShow = () => {
    if (this._isMounted) {
      this.setState({ show: !this.state.show });
    }
  };

  render() {
    return (
      <Toast
        className={css.toast}
        show={this.state.show}
        onClick={() => this.props.onClick(this.props.tid)}
        onClose={this.toggleShow}
      >
        <Toast.Header className={`${css.header} ${css.red}`}>
          <FontAwesomeIcon
            className={css.icon}
            icon={['far', 'exclamation-circle']}
          />
          <div className={css.title}>
            {this.props.name} {this.props.message}
          </div>
        </Toast.Header>
        <Toast.Body className={css.body}>
          <div>Location: {this.props.room}</div>
          <div>Time: {this.props.time}</div>
          <NavLink to={ROUTES.MOTES}>Learn More</NavLink>
        </Toast.Body>
      </Toast>
    );
  }
}

export default MPopup;
