import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAppContext } from '../../context/withAppContext';
import css from './Toaster.module.scss';
import Popup from './Popup.jsx';
import MPopup from './MPopup.jsx';

class Toaster extends Component {
  _isMounted = false;
  state = { closedAlerts: [] };

  componentDidMount() {
    this._isMounted = true;
    const rdb = this.props.firebase.db;
    const liveRef = rdb
      .ref()
      .child('motes')
      .child('current');
    liveRef.on('value', snapshot => {
      //console.log('live mote update');
      const allMotes = Object.values(snapshot.val());
      const authMotes = this.props.context.state.motes;
      if (authMotes) {
        const ids = authMotes.map(mote => mote.tid);
        const motes = allMotes.filter(mote => ids.includes(mote.tid));
        const offline = motes.filter(mote => mote.online === 0);
        if (this._isMounted) {
          this.setState({
            liveMotes: motes,
            liveMotesLoaded: true,
            offlineMotes: offline,
            offlineAlerts: offline.length
          });
        }
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePopupClick = tid => {
    this.setState({ closedAlerts: [...this.state.closedAlerts, tid] });
  };

  render() {
    return (
      <div className={css.toaster} aria-live="polite" aria-atomic="true">
        <div className={css.wrapper}>
          {this.state.offlineAlerts > 0 && (
            <div>
              {this.state.offlineMotes.map(mote =>
                !this.state.closedAlerts.includes(mote.tid) ? (
                  <MPopup
                    key={`popup_${mote.tid}`}
                    tid={mote.tid}
                    name={mote.name}
                    room={mote.location.room_name}
                    time={mote.true_time}
                    message={'is Offline'}
                    onClick={this.handlePopupClick}
                  />
                ) : (
                  <></>
                )
              )}
            </div>
          )}
          {this.props.context.state.outdatedNotice && (
            <div>
              <Popup
                key={`popup_${this.props.context.state.availableVersion}`}
                title="Out of Date"
                message={`You are out of date, please refresh to get the latest version: ${this.props.context.state.availableVersion}`}
                onClick={this.props.context.closeNotice}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withFirebase(withAppContext(Toaster));
