import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAppContext } from '../../context/withAppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './Motes.module.scss';
import Mote from './Mote';

class Motes extends Component {
  _isMounted = false;
  state = {};
  componentDidMount() {
    this._isMounted = true;

    const rdb = this.props.firebase.db;

    const liveRef = rdb
      .ref()
      .child('motes')
      .child('current');
    liveRef.on('value', snapshot => {
      //console.log('live mote update');
      const allMotes = Object.values(snapshot.val());
      const authMotes = this.props.context.state.motes;
      if (authMotes) {
        const ids = authMotes.map(mote => mote.tid);
        // const motes = this.props.context.state.isAdmin
        //   ? allMotes
        //   : allMotes.filter(mote => ids.includes(mote.tid));
        const motes = allMotes.filter(mote => ids.includes(mote.location.mote_id + ''));
        const offline = motes.filter(mote => mote.online === 0);
        if (this._isMounted) {
          this.setState({ liveMotes: motes });
          this.setState({ liveMotesLoaded: true });
          this.setState({ offlineMotes: offline });
        }
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <main className={css.main}>
        {this.props.context.state.loading && (
          <div className={css.loading}>
            <FontAwesomeIcon className={css.icon} icon={['fas', 'sync']} spin />
          </div>
        )}
        {this.state.liveMotesLoaded && (
          <div className={css.gridContainer}>
            {this.state.liveMotes.map(mote => (
              <Mote
                key={mote.location.mote_id}
                data={mote}
                time={mote.date_time.date}
                active={mote.active}
                online={mote.online}
                alert={mote.event.name}
              />
            ))}
          </div>
        )}
      </main>
    );
  }
}

export default withFirebase(withAppContext(Motes));
