import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { withAuthorization } from '../components/Session';
import css from './PrivateRoute.module.scss';
import Toaster from '../components/Toaster/Toaster';
import Header from '../components/Header/Header';
import Sidenav from '../components/Sidenav/Sidenav';
import Footer from '../components/Footer/Footer';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [navOpen, setNavOpen] = useState(false);
  const gridClass = navOpen ? css.navOpen : css.navClosed;
  return (
    <Route
      {...rest}
      component={props => (
        <div>
          <Header navOpen={navOpen} setNavOpen={setNavOpen} />
          <div className={`${css.gridContainer} ${gridClass}`}>
            <Toaster />
            <Sidenav navOpen={navOpen} setNavOpen={setNavOpen} />
            <Component {...props} />
            <Footer />
          </div>
        </div>
      )}
    />
  );
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(PrivateRoute);
