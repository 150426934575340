import React, { Component } from 'react';
import { withAppContext } from '../../context/withAppContext';
import { withFirebase } from '../Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './Chart.module.scss';
import { Line } from 'react-chartjs-2';

class Chart extends Component {
  _isMounted = false;
  state = {
    loading: true,
    datasets: [],
    labels: [],
    max: 0,
    min: 100
  };

  componentDidMount() {
    this._isMounted = true;
    const end = this.props.end;
    const start = this.props.start;
    //console.log('2', start, end);
    const { points } = this.props;
    const { activeSite: sid } = this.props.context.state;
    const { rid, name, color, field } = this.props;

    this._isMounted = true;
    //this.intervalId = setInterval(this.timer.bind(this), 60000);

    const rdb = this.props.firebase.db;
    const rdbRef = rdb
      .ref('motes/floorplan/' + this.props.context.state.activeSite + '');
    rdbRef.orderByChild('date_time/unix_ms').on('value', snapshot => {
      if(snapshot.val()) {
        let logs = Object.values(snapshot.val());
        if (this._isMounted) {
          this.setChart(logs);
        }
      }
    });
    
    //this.getRoomData(sid, rid, name, start, end, points, color, field);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { end, start, rid, name, color, field, points } = prevProps;
    const { activeSite: sid } = this.props.context.state;
    const {
      rid: newRid,
      name: newName,
      start: newStart,
      end: newEnd,
      color: newColor,
      field: newField,
      points: newPoints
    } = this.props;
    if (
      end !== newEnd ||
      start !== newStart ||
      rid !== newRid ||
      name !== newName ||
      color !== newColor ||
      field !== newField ||
      points !== newPoints
    ) {
      this.setState({
        loading: true,
        datasets: [],
        labels: [],
        max: 0,
        min: 100
      });
      this.getRoomData(
        sid,
        newRid,
        newName,
        newStart,
        newEnd,
        newPoints,
        newColor,
        newField
      );
    }
  }

  setChart(data) {
    const chartData = [];
    data.forEach(function (value) {
      //console.log(value.data);
      //humidity, light, temp
      chartData.push(value.data.light);
    });
    console.log(chartData);
    const currentMax = this.state.max;
    const currentMin = this.state.min;
    const newMax = Math.max.apply(null, chartData) + 3;
    const minCalc = Math.min.apply(null, chartData) - 3;
    const newMin = minCalc > 0 ? minCalc : 0;
    const max = currentMax > newMax ? currentMax : newMax;
    const min = currentMin < newMin ? currentMin : newMin;
    const color="#333";
    const bgColor = this.hexToRgbA(color);
    const dataset = {
      label: 'humidity',
      backgroundColor: bgColor,
      borderColor: color,
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: chartData
    };
    this.setState({
      datasets: [...this.state.datasets, dataset],
      max,
      min,
      loading: false
    });
  }

  getRoomData = (sid, rid, name, start, end, points, color, field) => {
    //console.log('3', start, end);
    const url = process.env.REACT_APP_NORMALIZED_DATA_URL;
    const endPoint = `${url}/data/normalized/${sid}/${rid}/${points}/${start}/${end}/${field}`;
    //console.log(endPoint);
    fetch(endPoint)
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          const currentMax = this.state.max;
          const currentMin = this.state.min;
          const newMax = Math.max.apply(null, data) + 3;
          const minCalc = Math.min.apply(null, data) - 3;
          const newMin = minCalc > 0 ? minCalc : 0;
          const max = currentMax > newMax ? currentMax : newMax;
          const min = currentMin < newMin ? currentMin : newMin;
          const bgColor = this.hexToRgbA(color);
          const dataset = {
            label: name,
            backgroundColor: bgColor,
            borderColor: color,
            pointHoverBackgroundColor: '#fff',
            borderWidth: 2,
            data: data
          };
          this.setState({
            datasets: [...this.state.datasets, dataset],
            max,
            min
          });
        }

        return fetch(`${url}/data/labels/${points}/${start}/${end}/m-d g:ia`);
      })
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          this.setState({ labels: data, loading: false });
        }
      });
  };

  hexToRgbA = hex => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return (
        'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)'
      );
    }
    throw new Error('Bad Hex');
  };

  render() {
    const width = window.innerWidth;
    const isDesktop = width < 750 ? false : true;
    const chartHeight = isDesktop ? 300 : 150;
    const mainChart = {
      labels: this.state.labels,
      datasets: this.state.datasets
    };
    const mainChartOpts = {
      tooltips: {
        enabled: true,
        intersect: true,
        mode: 'index',
        position: 'nearest',
        callbacks: {
          labelColor: function(tooltipItem, chart) {
            return {
              backgroundColor:
                chart.data.datasets[tooltipItem.datasetIndex].borderColor
            };
          }
        }
      },
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: 'white',
              fontSize: 9
            },
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              fontColor: 'white',
              fontSize: 10,
              maxRotation: 0,
              beginAtZero: false,
              maxTicksLimit: 5,
              stepSize: Math.ceil(this.state.max / 5),
              max: this.state.max,
              min: this.state.min
            }
          }
        ]
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3
        },
        line: {
          tension: 0 // disables bezier curves
        }
      }
    };
    return (
      <>
        {this.state.loading && (
          <div className={css.card}>
            <div className={css.cardBody}>
              <div className={css.loader}>
                <FontAwesomeIcon
                  className={css.icon}
                  icon={['fas', 'sync']}
                  spin
                />
              </div>
            </div>
          </div>
        )}
        {!this.state.loading && (
          <div className={css.card}>
            <div className={css.cardBody}>
              <Line
                data={mainChart}
                options={mainChartOpts}
                height={chartHeight}
              />
            </div>
            <div className={css.cardFooter} />
          </div>
        )}
      </>
    );
  }
}

export default withFirebase(withAppContext(Chart));
