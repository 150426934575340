import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import css from './Admin.module.scss';

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }
  
  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <main className={css.main}>
        <h1>Admin</h1>

        {loading && <div>Loading ...</div>}

        <UserList users={users} />
      </main>
    );
  }
}

const UserList = ({ users }) => (
  <div className={css.mainOverview}>
    {users.map(user => (
      <div key={user.uid} className={css.card}>
        <div className={css.body}>
          <h5 className={css.title}>{user.username}</h5>
          <div><strong>E-Mail:</strong> {user.email}</div>
          <a href={`/account/${user.uid}`}>Edit</a>
        </div>
      </div>
    ))}
  </div>
);


export default withFirebase(AdminPage);