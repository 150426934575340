//signing flow
export const SIGN_UP = '/signup';
export const SIGN_IN = '/';
export const PASSWORD_FORGET = '/pw-forget';

//user pages
export const ACCOUNT = '/account';
export const ADMIN = '/admin';

//content pages
export const DASHBOARD = '/dashboard';
export const MOTES = '/motes';
export const ENVIRONMENT = '/history';
export const ACTIVITY = '/events';
export const FLOORPLAN = '/floorplan';
export const ROSEBOX_FLOORPLAN = '/rosebox-floorplan';
export const LOG = '/log';
