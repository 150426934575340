import React, { Component } from 'react';
import { withAppContext } from '../../../context/withAppContext';
import css from './Alert.module.scss';
import { Bar } from 'react-chartjs-2';

class Alert extends Component {
  _isMounted = false;

  state = { dataset: [], labels: [], max: 0, min: 100 };

  componentDidMount() {
    this._isMounted = true;
    const stop = this.getTimestamp(0);
    //const stop = 1554730357;
    const start = this.getTimestamp(24);
    //const start = 1554630357;
    const points = 60;

    const { tid, labels, name, color } = this.props;

    this.getAlertData(tid, labels, name, start, stop, points, color);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAlertData = (tid, labels, name, start, stop, points, color) => {
    const url = process.env.REACT_APP_NORMALIZED_DATA_URL;
    const endPoint = `${url}/data/count/${tid[0]}/${points}/${start}/${stop}`;
    const endPoint2 = `${url}/data/count/${tid[1]}/${points}/${start}/${stop}`;
    const { activeSite, activeRoom, activeMote } = this.props.context.state;
    const opts = this.generateFetchBody(activeSite, activeRoom, activeMote);
    fetch(endPoint, { method: 'POST', body: JSON.stringify(opts) })
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          const currentMax = this.state.max;
          const currentMin = this.state.min;
          const newMax = Math.max.apply(null, data) + 3;
          const newMin = Math.min.apply(null, data) - 3;
          const max = currentMax > newMax ? currentMax : newMax;
          const min = currentMin < newMin ? currentMin : newMin;
          const dataset = {
            label: labels[0],
            backgroundColor: this.hexToRgbA(color, 0.5),
            borderColor: color,
            borderWidth: 1,
            hoverBackgroundColor: this.hexToRgbA(color, 0.7),
            hoverBorderColor: color,
            data: data
          };
          this.setState({
            dataset: [...this.state.dataset, dataset],
            max,
            min
          });
          //console.log(this.state.dataset);
        }
        return fetch(endPoint2, { method: 'POST', body: JSON.stringify(opts) });
      })
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          const currentMax = this.state.max;
          const currentMin = this.state.min;
          const newMax = Math.max.apply(null, data) + 3;
          const newMin = Math.min.apply(null, data) - 3;
          const max = currentMax > newMax ? currentMax : newMax;
          const min = currentMin < newMin ? currentMin : newMin;
          const dataset = {
            label: labels[1],
            backgroundColor: this.hexToRgbA(color, 0.2),
            borderColor: color,
            borderWidth: 1,
            hoverBackgroundColor: this.hexToRgbA(color, 0.4),
            hoverBorderColor: color,
            data: data
          };
          this.setState({
            dataset: [...this.state.dataset, dataset],
            max,
            min
          });
          //console.log(this.state.dataset);
        }
        return fetch(
          `${url}/data/labels/${points}/${start}/${stop}/Y-m-d G:m:s`
        );
      })
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          this.setState({ labels: data });
        }
      });
  };

  generateFetchBody = (activeSite, activeRoom, activeMote) => {
    if (activeSite && !activeRoom && !activeMote) {
      return { site: activeSite };
    } else if (activeSite && activeRoom && !activeMote) {
      return { site: activeSite, room: activeRoom };
    } else if (activeSite && activeRoom && activeMote) {
      return { site: activeSite, room: activeRoom, location: activeMote };
    } else {
      return {};
    }
  };

  getTimestamp = offsetHours => {
    const base = Math.floor(Date.now() / 1000) - 5 * 60;
    const timestamp = base - offsetHours * 60 * 60;
    return timestamp;
  };

  hexToRgbA = (hex, opacity) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return (
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        ',' +
        opacity +
        ')'
      );
    }
    throw new Error('Bad Hex');
  };

  render() {
    const width = window.innerWidth;
    const isDesktop = width < 750 ? false : true;
    const chartHeight = isDesktop ? 300 : 150;
    const chart = {
      labels: this.state.labels,
      datasets: this.state.dataset
    };
    const options = {
      tooltips: {
        enabled: true
      },
      maintainAspectRatio: false,
      legend: {
        labels: {
          fontColor: '#e4e7ea'
        }
      },
      scales: {
        xAxes: [
          {
            ticks: {
              display: false //this will remove only the label
            },
            stacked: true
          }
        ]
      }
    };
    return (
      <div className={css.card}>
        <div className={css.cardTitle}>
          <h2>{this.props.name}</h2>
        </div>
        <div className={css.cardBody}>
          <Bar data={chart} options={options} height={chartHeight} />
        </div>
        <div className={css.cardFooter} />
      </div>
    );
  }
}

export default withAppContext(Alert);
