import React, { Component } from 'react';
import { withAppContext } from '../../context/withAppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toast from 'react-bootstrap/Toast';
import css from './Popup.module.scss';

class Popup extends Component {
  _isMounted = false;
  state = { show: true };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleShow = () => {
    if (this._isMounted) {
      this.setState({ show: !this.state.show });
      this.props.context.closeNotice(); //TODO: Not ideal, this should be abstracted away somehow to be more generic
    }
  };

  render() {
    return (
      <Toast
        className={css.toast}
        show={this.state.show}
        onClose={this.toggleShow}
      >
        <Toast.Header className={`${css.header} ${css.red}`}>
          <FontAwesomeIcon
            className={css.icon}
            icon={['far', 'exclamation-circle']}
          />
          <div className={css.title}>
            {this.props.title}
          </div>
        </Toast.Header>
        <Toast.Body className={css.body}>
          <div>{this.props.message}</div>
        </Toast.Body>
      </Toast>
    );
  }
}

export default withAppContext(Popup);
