import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAppContext } from '../../context/withAppContext';
import css from './Activity.module.scss';
import Loader from './../Loader/Loader';
import FilterMotes from '../Filters/FilterMotes';
import Alert from './Charts/Alert';

class Activity extends Component {
  _isMounted = false;
  state = {};
  componentDidMount() {
    this._isMounted = true;
    //const { activeSite: sid, rooms } = this.props.context.state;

    const rdb = this.props.firebase.db;
    const rdbRef = rdb
      .ref()
      .child('motes')
      .child('log');
    rdbRef.on('value', snapshot => {
      let logs = Object.values(snapshot.val());
      if (this._isMounted) {
        const siteID = this.props.context.state.activeSite + '';
        const justThisSite = logs.filter(
          log => log.location.site_id === siteID
        );
        const isDoor = justThisSite.filter(log => log.event.id === 77).length
          ? true
          : false;
        const isMotion = justThisSite.filter(log => log.event.id === 61).length
          ? true
          : false;
        const islight = justThisSite.filter(log => log.data.light > 0).length
          ? true
          : false;
        //console.log(isDoor, isMotion, islight);
        this.setState({ isDoor, isMotion, islight });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <main className={css.main}>
        {this.props.context.state.loading && <Loader />}
        {!this.props.context.state.loading && (
          <>
            <div className={css.settings}>
              <FilterMotes />
            </div>
            <div className={css.charts}>
              <Alert
                key="door-alerts"
                tid={[77, 78]}
                labels={['open', 'close']}
                name="Doors"
                color="#20a8d8"
              />
              <Alert
                key="motion-alerts"
                tid={[61, 62]}
                labels={['start', 'stopped']}
                name="Motion"
                color="#4dbd74"
              />
              <Alert
                key="light-alerts"
                tid={[64, 65]}
                labels={['on', 'off']}
                name="Lights"
                color="#ffc107"
              />
            </div>
          </>
        )}
      </main>
    );
  }
}

export default withFirebase(withAppContext(Activity));
