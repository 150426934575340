import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './NavOpen.module.scss';

class NavOpen extends Component {
  render() {
    return (
      <div
        onClick={() => this.props.setNavOpen(true)}
        className={`${css.navOpen} ${css.show}`}
      >
        <FontAwesomeIcon className={css.icon} icon={['fas', 'bars']} />
      </div>
    );
  }
}

export default NavOpen;
