import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAppContext } from '../../context/withAppContext';
import moment from 'moment';
import css from './RoseboxFloorplan.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Event from './Event.jsx';

class Floorplan extends Component {
  _isMounted = false;
  state = {
    now: null,
    history: [],
    log: [],
    logFiltered: [],
    entries: 20,
    heartbeat: false,
    green: { door: 'closed', motion: 'stop', light: 'off' },
    red: { door: 'closed', motion: 'stop', light: 'off' }
  };
  
  timer() {
    this.setState({
      now: moment().unix()
    })
    this.setGreen(this.state.history[0]);
    this.setRed(this.state.history[0]);
  }

  componentDidMount() {
    this._isMounted = true;
    this.intervalId = setInterval(this.timer.bind(this), 60000);

    const rdb = this.props.firebase.db;
    const rdbRef = rdb
      .ref('motes/floorplan/' + this.props.context.state.activeSite + '');
    rdbRef.orderByChild('date_time/unix_ms').limitToLast(20).on('value', snapshot => {
      if(snapshot.val()) {
        let logs = Object.values(snapshot.val());
        if (this._isMounted) {
          this.setState({ history: logs.reverse() });
          this.setGreen(logs[0]);
          this.setRed(logs[0]);
        }
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalId);
  }

  setGreen = list => {
    //console.log('Green List: ', list);
    if (list) {
      const room = list.location.room_name;
      const event = list.event.name;
      let { green } = this.state;
      if (room === 'Green') {
        switch (event) {
          case 'Door Open':
            green = { ...this.state.green, door: 'open' };
            break;
          case 'Door Closed':
            green = { ...this.state.green, door: 'closed' };
            break;
          case 'Motion Start':
            green = { ...this.state.green, motion: 'start' };
            break;
          case 'Motion Stopped':
            green = { ...this.state.green, motion: 'stop' };
            break;
          case 'Lights On':
            green = { ...this.state.green, light: 'on' };
            break;
          case 'Lights Off':
            green = { ...this.state.green, light: 'off' };
            break;
          default:
        }
      }
      //console.log('Green: ', green);
      this.setState({ green });
    }
  };

  setRed = list => {
    //console.log('Red List: ', list);
    if (list) {
      const room = list.location.room_name;
      const event = list.event.name;
      let { red } = this.state;
      if (room === 'Red') {
        switch (event) {
          case 'Door Open':
            red = { ...this.state.red, door: 'open' };
            break;
          case 'Door Closed':
            red = { ...this.state.red, door: 'closed' };
            break;
          case 'Motion Start':
            red = { ...this.state.red, motion: 'start' };
            break;
          case 'Motion Stopped':
            red = { ...this.state.red, motion: 'stop' };
            break;
          case 'Lights On':
            red = { ...this.state.red, light: 'on' };
            break;
          case 'Lights Off':
            red = { ...this.state.red, light: 'off' };
            break;
          default:
        }
      }
      //console.log('Red: ', this.state.red);
      this.setState({ red });
    }
  };

  toggleHeartbeats = () => {
    if (this.state.heartbeat) {
      this.setState({ heartbeat: false });
    } else {
      this.setState({ heartbeat: true });
    }
  };

  render() {
    //console.log(this.state.logFiltered);
    let DoorGreenClass = css.doorGreenOpen,
      DoorRedClass = css.doorRedClosed,
      MotionGreenClass = css.motionGreenStop,
      MotionRedClass = css.motionRedStop,
      LightsGreenClass = css.lightsGreenOff,
      LightsRedClass = css.lightsRedOff,
      List;
    if (this.state.history.length > 0) {
      switch (this.state.green.door) {
        case 'open':
          DoorGreenClass = css.doorGreenOpen;
          break;
        case 'closed':
          DoorGreenClass = css.doorGreenClosed;
          break;
        default:
          DoorGreenClass = css.doorGreenClosed;
      }

      switch (this.state.green.motion) {
        case 'start':
          MotionGreenClass = css.motionGreenStart;
          break;
        case 'stop':
          MotionGreenClass = css.motionGreenStop;
          break;
        default:
          MotionGreenClass = css.motionGreenStop;
      }

      switch (this.state.green.light) {
        case 'on':
          LightsGreenClass = css.lightsGreenOn;
          break;
        case 'off':
          LightsGreenClass = css.lightsGreenOff;
          break;
        default:
          LightsGreenClass = css.lightsGreenOff;
      }

      switch (this.state.red.door) {
        case 'open':
          DoorRedClass = css.doorRedOpen;
          break;
        case 'closed':
          DoorRedClass = css.doorRedClosed;
          break;
        default:
          DoorRedClass = css.doorRedClosed;
      }

      switch (this.state.red.motion) {
        case 'start':
          MotionRedClass = css.motionRedStart;
          break;
        case 'stop':
          MotionRedClass = css.motionRedStop;
          break;
        default:
          MotionRedClass = css.motionRedStop;
      }

      switch (this.state.red.light) {
        case 'on':
          LightsRedClass = css.lightsRedOn;
          break;
        case 'off':
          LightsRedClass = css.lightsRedOff;
          break;
        default:
          LightsRedClass = css.lightsRedOff;
      }

      List = this.state.history.map((history, index) => (
        <Event
          key={index}
          id={history.event.id}
          name={history.event.name}
          time={history.date_time.time}
          unix={history.date_time.unix}
          room={history.location.room_name}
          mote={history.location.mote_name}
        />
      ));
    } else {
      List = (
        <div className={css.noResults}>
          <span className={css.noResultsIcon}>
            <FontAwesomeIcon icon={['fa', 'stopwatch']} size="3x" />
          </span>
          <span className={css.noResultsMessage}>
            It has been more then an hour since activity was last detected.
          </span>
        </div>
      );
    }
    return (
      <main className={css.main}>
        <div className={css.history}>
          {List}
        </div>
        <div className={css.floorplan}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 655.38 335.52">
            <g id="Walls-Fixed">
              <path
                className={css.cls1}
                d="M141.6,70.91c-47.67-.24-137.4.54-137.4.54L4.08,337l655.26.12V70.85H521.94"
                transform="translate(-4.02 -1.62)"
              />
              <line
                className={css.cls1}
                x1="373.38"
                y1="57.66"
                x2="282.06"
                y2="57.66"
              />
              <line
                className={css.cls1}
                x1="327.72"
                y1="57.66"
                x2="327.66"
                y2="335.4"
              />
            </g>
            <g id="DoorRed" className={`${css.doorObject} ${DoorRedClass}`}>
              <path
                className={`${css.cls1} ${css.door2}`}
                d="M508.62,67a7.05,7.05,0,1,1,7,7A7.05,7.05,0,0,1,508.62,67Z"
                transform="translate(-4.02 -1.62)"
              />
              <line
                className={css.cls1}
                x1="515.06"
                y1="59.19"
                x2="390.42"
                y2="0.05"
              />
            </g>
            <g id="DoorGreen" className={`${css.doorObject} ${DoorGreenClass}`}>
              <path
                className={`${css.cls1} ${css.door1}`}
                d="M154.81,67a7.06,7.06,0,1,0-7.06,7A7.06,7.06,0,0,0,154.81,67Z"
                transform="translate(-4.02 -1.62)"
              />
              <line
                className={css.cls1}
                x1="140.33"
                y1="59.19"
                x2="264.96"
                y2="0.05"
              />
            </g>
            <g id="SensorRed">
              <polygon
                className={`${css.cls1} ${LightsRedClass}`}
                points="557.77 151.76 605.8 179.49 605.8 234.95 557.77 262.67 509.75 234.95 509.75 179.49 557.77 151.76"
              />
              <path
                className={`${css.cls1} ${MotionRedClass}`}
                d="M511.9,193S495,194,496.75,210.69c1.42,13.61,15.19,14.08,15.19,14.08Z"
                transform="translate(-4.02 -1.62)"
              />
            </g>
            <g id="SensorGreen">
              <polygon
                className={`${css.cls1} ${LightsGreenClass}`}
                points="102.17 151.76 54.14 179.49 54.14 234.95 102.17 262.67 150.19 234.95 150.19 179.49 102.17 151.76"
              />
              <path
                className={`${css.cls1} ${MotionGreenClass}`}
                d="M156.08,193s16.88.94,15.14,17.67C169.81,224.3,156,224.77,156,224.77Z"
                transform="translate(-4.02 -1.62)"
              />
            </g>
          </svg>
        </div>
      </main>
    );
  }
}

export default withFirebase(withAppContext(Floorplan));
