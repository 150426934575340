import React, { Component } from 'react';
import { withAppContext } from '../../context/withAppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './Loader.module.scss';

class Loader extends Component {
  render() {
    return (
      <div className={css.loading}>
        <FontAwesomeIcon className={css.icon} icon={['fas', 'sync']} spin />
      </div>
    );
  }
}

export default withAppContext(Loader);
