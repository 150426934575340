import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './LoginForm.module.scss';
import { Form, InputGroup, Button, Container } from 'react-bootstrap';
import { withAppContext } from '../../context/withAppContext';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <Container>
    <SignInForm />
  </Container>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        // Only redirect to floorplan if its Matt or Martin's account
        email === "matt@monte.net" || "mmonte1620@gmail.com" ? this.props.history.push(ROUTES.FLOORPLAN) : this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit} className={css.form}>
        <h1> Login</h1>
        <p className="text-muted">Sign In to your account</p>
        <Form.Group controlId="validationCustomUsername">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className={css.inputIcon} id="inputGroupPrepend">
                <FontAwesomeIcon
                  className={css.userIcon}
                  icon={['far', 'envelope']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              placeholder="Email Address"
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your email.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formGridPassword">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className={css.inputIcon} id="inputGroupPrepend">
                <FontAwesomeIcon
                  className={css.lockIcon}
                  icon={['far', 'lock-alt']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a password.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Check
            className={`text-muted ${css.agree}`}
            label="Agree to terms and conditions"
            feedback="You must agree before submitting."
          />
        </Form.Group>
        <Button
          disabled={isInvalid}
          className="px-4 btn btn-primary"
          type="submit"
        >
          Login
        </Button>

        {error && <p className="text-muted">{error.message}</p>}

        <PasswordForgetLink />
        <SignUpLink />
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
  withAppContext
)(SignInFormBase);

export default SignInPage;

export { SignInForm };
