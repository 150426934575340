import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { faBoxingGlove as faBoxingGloveLight } from '@fortawesome/pro-light-svg-icons';
import { faBoxingGlove as faBoxingGloveSolid } from '@fortawesome/pro-solid-svg-icons';
import { faBars as faBarsSolid } from '@fortawesome/pro-solid-svg-icons';
import { faTimes as faTimesSolid } from '@fortawesome/pro-solid-svg-icons';
import { faSync as faSyncSolid } from '@fortawesome/pro-solid-svg-icons';
import { faGlobe as faGlobeRegular } from '@fortawesome/pro-regular-svg-icons';
import { faCog as faCogLight } from '@fortawesome/pro-light-svg-icons';
import { faCog as faCogSolid } from '@fortawesome/pro-solid-svg-icons';
import { faChartNetwork as faChartNetworkLight } from '@fortawesome/pro-light-svg-icons';
import { faCrosshairs as faCrosshairsLight } from '@fortawesome/pro-light-svg-icons';
import { faChartBar as faChartBarLight } from '@fortawesome/pro-light-svg-icons';
import { faObjectGroup as faObjectGroupLight } from '@fortawesome/pro-light-svg-icons';
import { faTachometerAltFast as faTachometerAltFastLight } from '@fortawesome/pro-light-svg-icons';
import { faThermometerFull as faThermometerFullSolid } from '@fortawesome/pro-solid-svg-icons';
import { faHumidity as faHumiditySolid } from '@fortawesome/pro-solid-svg-icons';
import { faClipboardList as faClipboardListLight } from '@fortawesome/pro-light-svg-icons';
import { faClock as faClockLight } from '@fortawesome/pro-light-svg-icons';
import { faUser as faUserRegular } from '@fortawesome/pro-regular-svg-icons';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/pro-regular-svg-icons';
import { faPhone as faPhoneRegular } from '@fortawesome/pro-regular-svg-icons';
import { faLockAlt as faLockAltRegular } from '@fortawesome/pro-regular-svg-icons';
import { faChartLine as faChartLineRegular } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle as faExclamationCircleRegular } from '@fortawesome/pro-regular-svg-icons';
import { faHeartbeat as faHeartBeatSolid } from '@fortawesome/pro-solid-svg-icons';
import { faRunning as faRunningSolid } from '@fortawesome/pro-solid-svg-icons';
import { faRunning as faRunningLight } from '@fortawesome/pro-light-svg-icons';
import { faDoorOpen as faDoorOpenSolid } from '@fortawesome/pro-solid-svg-icons';
import { faDoorClosed as faDoorClosedSolid } from '@fortawesome/pro-solid-svg-icons';
import { faLightbulbOn as faLightbuldOnSolid } from '@fortawesome/pro-solid-svg-icons';
import { faLightbulb as faLightbuldSolid } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationTriangle as faExclamationTriangleSolid } from '@fortawesome/pro-solid-svg-icons';
import { faPowerOff as faPowerOffSolid } from '@fortawesome/pro-solid-svg-icons';
import { faStopwatch as faStopwatchSolid } from '@fortawesome/pro-solid-svg-icons';
import { faPlug as faPlugSolid } from '@fortawesome/pro-solid-svg-icons';

library.add(
  faCircleSolid,
  faBoxingGloveLight,
  faBoxingGloveSolid,
  faBarsSolid,
  faTimesSolid,
  faSyncSolid,
  faGlobeRegular,
  faCogLight,
  faCogSolid,
  faChartNetworkLight,
  faCrosshairsLight,
  faChartBarLight,
  faObjectGroupLight,
  faTachometerAltFastLight,
  faThermometerFullSolid,
  faHumiditySolid,
  faClipboardListLight,
  faClockLight,
  faUserRegular,
  faLockAltRegular,
  faEnvelopeRegular,
  faPhoneRegular,
  faChartLineRegular,
  faExclamationCircleRegular,
  faHeartBeatSolid,
  faRunningSolid,
  faRunningLight,
  faDoorOpenSolid,
  faDoorClosedSolid,
  faLightbuldOnSolid,
  faLightbuldSolid,
  faExclamationTriangleSolid,
  faPowerOffSolid,
  faStopwatchSolid,
  faPlugSolid
);
