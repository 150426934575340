import React, { Component } from 'react';
import { withAppContext } from '../../context/withAppContext';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'react-bootstrap/Nav';
import css from './Sidenav.module.scss';

class Sidenav extends Component {
  state = {
    key: 'link-home'
  };

  render() {
    const asideClass = this.props.navOpen ? css.open : css.closed;
    let showControls = css.show;
    return (
      <aside className={`${css.sidenav} ${asideClass}`}>
        <div
          onClick={() => this.props.setNavOpen(false)}
          className={`${css.closeIcon} ${showControls}`}
        >
          <FontAwesomeIcon className={css.icon} icon={['fas', 'times']} />
        </div>
        <Nav defaultActiveKey="/home" className="flex-column">
          <Nav.Link
            eventKey="link-home"
            as="div"
            onSelect={() => this.props.setNavOpen(false)}
          >
            <NavLink
              exact={true}
              activeClassName="is-active"
              to={ROUTES.DASHBOARD}
            >
              <FontAwesomeIcon
                className={css.menuIcon}
                icon={['fal', 'tachometer-alt-fast']}
                style={{ marginRight: '15px' }}
                fixedWidth
              />
              Dashboard
            </NavLink>
          </Nav.Link>
          <Nav.Link
            eventKey="link-motes"
            as="div"
            onSelect={() => this.props.setNavOpen(false)}
          >
            <NavLink activeClassName="is-active" to={ROUTES.MOTES}>
              <FontAwesomeIcon
                className={css.menuIcon}
                icon={['fal', 'chart-network']}
                style={{ marginRight: '15px' }}
                fixedWidth
              />
              Sensors
            </NavLink>
          </Nav.Link>
          <Nav.Link
            eventKey="link-environment"
            as="div"
            onSelect={() => this.props.setNavOpen(false)}
          >
            <NavLink activeClassName="is-active" to={ROUTES.ENVIRONMENT}>
              <FontAwesomeIcon
                className={css.menuIcon}
                icon={['fal', 'clock']}
                style={{ marginRight: '15px' }}
                fixedWidth
              />
              History
            </NavLink>
          </Nav.Link>
          <Nav.Link
            eventKey="link-activity"
            as="div"
            onSelect={() => this.props.setNavOpen(false)}
          >
            <NavLink activeClassName="is-active" to={ROUTES.ACTIVITY}>
              <FontAwesomeIcon
                className={css.menuIcon}
                icon={['fal', 'running']}
                style={{ marginRight: '15px' }}
                fixedWidth
              />
              Events
            </NavLink>
          </Nav.Link>
          {this.props.context.state.activeSite === 52 && (
            <Nav.Link
              eventKey="link-floorplan"
              as="div"
              onSelect={() => this.props.setNavOpen(false)}
            >
              <NavLink activeClassName="is-active" to={ROUTES.FLOORPLAN}>
                <FontAwesomeIcon
                  className={css.menuIcon}
                  icon={['fal', 'object-group']}
                  style={{ marginRight: '15px' }}
                  fixedWidth
                />
                Floorplan
              </NavLink>
            </Nav.Link>
          )}
          {this.props.context.state.activeSite === 114 && (
            <Nav.Link
              eventKey="link-floorplan"
              as="div"
              onSelect={() => this.props.setNavOpen(false)}
            >
              <NavLink
                activeClassName="is-active"
                to={ROUTES.ROSEBOX_FLOORPLAN}
              >
                <FontAwesomeIcon
                  className={css.menuIcon}
                  icon={['fal', 'object-group']}
                  style={{ marginRight: '15px' }}
                  fixedWidth
                />
                Rosebox Floorplan
              </NavLink>
            </Nav.Link>
          )}
          <Nav.Link
            eventKey="link-activity"
            as="div"
            onSelect={() => this.props.setNavOpen(false)}
          >
            <NavLink activeClassName="is-active" to={ROUTES.LOG}>
              <FontAwesomeIcon
                className={css.menuIcon}
                icon={['fal', 'clipboard-list']}
                style={{ marginRight: '15px' }}
                fixedWidth
              />
              Log
            </NavLink>
          </Nav.Link>
        </Nav>
        <div
          title={
            this.props.context.state.outdated
              ? `Outdated! Current version is: ${this.props.context.state.availableVersion} refresh your browser to get the latest version`
              : 'You have the latest version!'
          }
          className={
            css.version +
            ' ' +
            (this.props.context.state.outdated
              ? css.versionOutdated
              : ' current')
          }
        >
          v{this.props.context.state.version}{' '}
          <span className="badge badge-pill badge-secondary">Beta</span>
        </div>
      </aside>
    );
  }
}

export default withAppContext(Sidenav);
