import React, { Component } from 'react';
import { withAppContext } from '../../context/withAppContext';
import css from './FilterMotes.module.scss';

class FilterMotes extends Component {
  _isMounted = false;

  state = {
    roomList: [],
    roomOptions: 'loading...',
    roomActive: 'all',
    moteList: [],
    moteOptions: 'loading...',
    moteActive: 'all'
  };
  componentDidMount() {
    this._isMounted = true;

    this.setState({
      roomList: this.props.context.state.rooms,
      moteList: this.props.context.state.motes
    });

    const roomOptions = this.props.context.state.rooms.map(room => (
      <option key={room.tid} value={room.tid}>
        {room.name}
      </option>
    ));
    this.setState({ roomOptions });

    const moteOptions = this.props.context.state.motes.map(mote => (
      <option key={mote.tid} value={mote.tid}>
        {mote.name}
      </option>
    ));
    this.setState({ moteOptions });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.roomActive !== this.props.context.state.activeRoom) {
      this.setState({ roomActive: this.props.context.state.activeRoom });
    }
    if (prevState.moteActive !== this.props.context.state.activeMote) {
      this.setState({ moteActive: this.props.context.state.activeMote });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.roomList && this.state.moteList && (
          <div className={css.settings}>
            <div className={css.filter}>
              <label htmlFor="roomSelect" className={css.label}>
                Room
              </label>
              <select
                name="roomSelect"
                onChange={this.props.context.roomChange}
                value={this.state.roomActive}
                className={`${css.list} ${css.room}`}
              >
                <option value="all">All</option>
                {this.state.roomOptions}
              </select>
            </div>
            {this.props.context.state.activeRoom && (
              <div className={css.filter}>
                <label htmlFor="moteSelect" className={css.label}>
                  Mote
                </label>
                <select
                  name="moteSelect"
                  onChange={this.props.context.moteChange}
                  value={this.state.moteActive}
                  className={`${css.list} ${css.mote}`}
                >
                  <option>All</option>
                  {this.state.moteOptions}
                </select>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withAppContext(FilterMotes);
