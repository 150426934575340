import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAppContext } from '../../context/withAppContext';
import css from './Environment.module.scss';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../datepicker.css';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import Dropdown from 'react-bootstrap/Dropdown';
import Loader from './../Loader/Loader';
import Room from './Room';

class Environment extends Component {
  _isMounted = false;
  state = {
    startDate: moment().subtract(1, 'days'),
    endDate: moment(),
    start: moment().subtract(1, 'days'),
    end: moment(),
    points: 25
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {}

  handleSelect = points => {
    this.setState({ points: points });
  };

  render() {
    return (
      <main className={css.main}>
        {this.props.context.state.loading && <Loader />}
        {!this.props.context.state.loading && (
          <>
            <div className={css.settings}>
              <div className={css.dateInstructions}>Date range: </div>
              <DateRangePicker
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                  //console.log('1', startDate.unix(), endDate.unix());
                  this.setState({ startDate, endDate });
                }} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                numberOfMonths={1}
                small
                onClose={({ startDate, endDate }) => {
                  //console.log('close', startDate.unix(), endDate.unix());
                  this.setState({ start: startDate, end: endDate });
                }}
              />
              <div className={css.pointsInstructions}>Data points: </div>
              <Dropdown className={css.pointsPicker}>
                <Dropdown.Toggle as="div" className={css.toggle}>
                  <div className={css.points}>{this.state.points}</div>
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight={true} className={css.dropdown}>
                  <Dropdown.Item onSelect={() => this.handleSelect(25)}>
                    25
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => this.handleSelect(50)}>
                    50
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => this.handleSelect(100)}>
                    100
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={css.rooms}>
              {this.props.context.state.rooms.map(room => (
                <Room
                  key={room.tid}
                  tid={room.tid}
                  name={room.name}
                  start={this.state.start.unix()}
                  end={this.state.end.unix()}
                  points={this.state.points}
                />
              ))}
            </div>
          </>
        )}
      </main>
    );
  }
}

export default withFirebase(withAppContext(Environment));
