import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from '../SignIn/LoginForm.module.scss';
import { Form, InputGroup, Button, Container } from 'react-bootstrap';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <Container>
    <PasswordForgetForm />
  </Container>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit} className={css.form}>
        <h1>Forgot Password?</h1>
        <p className="text-muted">Send an email to reset your password</p>
        <Form.Group controlId="validationCustomUsername">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className={css.inputIcon}
                id="inputGroupPrepend"
              >
                <FontAwesomeIcon
                  className={css.userIcon}
                  icon={['far', 'envelope']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="email"
              placeholder="Email Address"
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your email
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Button disabled={isInvalid} className="px-4 btn btn-primary" type="submit">
          Reset My Password
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };