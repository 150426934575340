import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import { withAppContext } from '../../context/withAppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import css from './UserMenu.module.scss';
import * as ROUTES from '../../constants/routes';

class UserMenu extends Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className={css.locations}>
            <Dropdown>
              <Dropdown.Toggle as="div" id="dropdown-basic">
                <FontAwesomeIcon className={css.icon} icon={['far', 'user']} />
                <div className={css.user}>{authUser.email}</div>
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight={true} className={css.dropdown}>
                <Dropdown.Item href={ROUTES.ACCOUNT}>Account</Dropdown.Item>
                {this.props.context.state.isAdmin && (
                  <Dropdown.Item href={ROUTES.ADMIN}>Admin</Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                  as="button"
                  onClick={this.props.firebase.doSignOut}
                >
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(withAppContext(UserMenu));
