import React, { Component } from 'react';
import { withAppContext } from '../../context/withAppContext';
import { withFirebase } from '../Firebase';
import css from './Log.module.scss';
import { Form } from 'react-bootstrap';

class Log extends Component {
  _isMounted = false;
  rdbRef;
  state = {
    log: [],
    logFiltered: [],
    entries: 25,
    heartbeat: false
  };

  componentDidMount() {
    //console.log("Mounted Log Component");
    this._isMounted = true;
    const rdb = this.props.firebase.db;
    if(this.props.context.state.activeSite !== null) {
      this.rdbRef = rdb
        .ref('motes/log')
      this.rdbRef.orderByChild('location/site_id').equalTo(this.props.context.state.activeSite + '').on('child_added', snapshot => {
        this.addLogEntry(snapshot.val());
      });
    }
  }

  componentWillUnmount() {
    //console.log("Unmounted Log Component");
    this._isMounted = false;
    if(this.rdbRef) {
      this.rdbRef.off();
    }
  }

  addLogEntry = (entry) => {
    if (this._isMounted) {
      this.setState(state => {
        const log = state.log.concat(entry);
        return {
          log
        };
      });
      this.filterLogs(this.state.heartbeat);
    }
  }

  filterLogs = (sid, heartbeat) => {
    const { log } = this.state;
    const heartbeatFilter = heartbeat
      ? log
      : log.filter(jts => jts.event.name !== 'Heartbeat');
    const mostRecentLogs = heartbeatFilter.slice(-this.state.entries);
    if (this._isMounted) {
      this.setState({ logFiltered: mostRecentLogs.reverse() });
    }
  };

  setEntries = num => {
    this.setState({ entries: num });
  };

  toggleHeartbeats = () => {
    if (this.state.heartbeat) {
      this.setState({ heartbeat: false });
      this.filterLogs(false);
    } else {
      this.setState({ heartbeat: true });
      this.filterLogs(true);
    }
  };

  render() {
    return (
      <main className={css.main}>
        <div className={css.filters}>
          <Form.Check
            className={css.checkbox}
            type="checkbox"
            id="hide-heartbeat"
            label="Show Sensor Heartbeats"
            onClick={this.toggleHeartbeats}
          />
        </div>
        <div className={css.log}>
          <div className={`${css.row} ${css.heading}`}>
            <div className={`${css.item} ${css.time}`}>Time</div>
            <div className={`${css.item} ${css.room}`}>Room</div>
            <div className={`${css.item} ${css.event}`}>Event</div>
            <div className={`${css.item} ${css.temp}`}>Temp (&deg;F)</div>
            <div className={`${css.item} ${css.light}`}>Light (%)</div>
            <div className={`${css.item} ${css.humidity}`}>Humidity (%)</div>
            <div className={`${css.item} ${css.amps}`}>Current (A)</div>
            <div className={`${css.item} ${css.date}`}>Date</div>
          </div>
          {this.state.logFiltered.map((log, index) => (
            <div key={index} className={css.row}>
              <div className={`${css.item} ${css.time}`}>
                {log.date_time.time}
              </div>
              <div className={`${css.item} ${css.room}`}>
                {log.location.room_name} ({log.location.mote_name})
              </div>
              <div className={`${css.item} ${css.event}`}>{log.event.name}</div>
              <div className={`${css.item} ${css.temp}`}>
                {log.data.temp && <span>Temp: </span>}
                {log.data.temp}
                <span>&deg;F</span>
              </div>
              <div className={`${css.item} ${css.light}`}>
                {log.data.light && <span>Light: </span>}
                {log.data.light}
                {log.data.light && <span>%</span>}
              </div>
              <div className={`${css.item} ${css.humidity}`}>
                {log.data.humidity && <span>Humidity: </span>}
                {log.data.humidity}
                {log.data.humidity && <span>%</span>}
              </div>
              <div className={`${css.item} ${css.amps}`}>
                {log.data.electrical_current && <span>Current: </span>}
                {log.data.electrical_current}
                {log.data.electrical_current && <span>A</span>}
              </div>
              <div className={`${css.item} ${css.date}`}>
                {log.date_time.date}
              </div>
            </div>
          ))}
        </div>
      </main>
    );
  }
}

export default withFirebase(withAppContext(Log));
