import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import Dashboard from '../components/Dashboard/Dashboard';
import Motes from '../components/Motes/Motes';
import Environment from '../components/Environment/Environment';
import Activity from '../components/Activity/Activity';
import Floorplan from '../components/Floorplan/Floorplan';
import RoseboxFloorplan from '../components/Floorplan/RoseboxFloorplan';
import Log from '../components/Log/Log';
import NotFound from '../components/NotFound/NotFound';
import SignUpPage from '../components/SignUp';
import SignInPage from '../components/SignIn';
import EditAccountPage from '../components/Admin/User';
import PasswordForgetPage from '../components/PasswordForget';
import AccountPage from '../components/Account';
import AdminPage from '../components/Admin';
import PrivateRoute from './PrivateRoute';
import * as ROUTES from '../constants/routes';
import { withAuthentication } from '../components/Session';

export const history = createHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>
        <Route exact path="/" component={SignInPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <PrivateRoute path="/account/:id" component={EditAccountPage} />
        <PrivateRoute path={ROUTES.ACCOUNT} component={AccountPage} />

        <PrivateRoute path={ROUTES.ADMIN} component={AdminPage} />
        <PrivateRoute path={ROUTES.DASHBOARD} component={Dashboard} />
        <PrivateRoute path={ROUTES.MOTES} component={Motes} />
        <PrivateRoute path={ROUTES.ENVIRONMENT} component={Environment} />
        <PrivateRoute path={ROUTES.ACTIVITY} component={Activity} />
        <PrivateRoute path={ROUTES.FLOORPLAN} component={Floorplan} />
        <PrivateRoute
          path={ROUTES.ROSEBOX_FLOORPLAN}
          component={RoseboxFloorplan}
        />
        <PrivateRoute path={ROUTES.LOG} component={Log} />
        <Route component={NotFound} />
      </Switch>
    </div>
  </Router>
);

export default withAuthentication(AppRouter);
