import React, { Component } from 'react';
import { withAppContext } from '../../context/withAppContext';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './Mote.module.scss';
import Temp from './Sensors/Temp/Temp';
import Light from './Sensors/Light/Light';
import Humidity from './Sensors/Humidity/Humidity';
import Amps from './Sensors/Amps/Amps';

class Mote extends Component {
  _isMounted = false;
  _timeUpdated = false;

  state = {
    data: false,
    updated: false,
    time: false,
    timeUpdated: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({ data: this.props.data });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._isMounted && this.props.time !== prevProps.time) {
      this._timeUpdated = true;
    } else {
      //this.setState({ timeUpdated: false });
      this._timeUpdated = false;
    }
  }

  ledStatus = (tid, active, online) => {
    const isActive = parseInt(active);
    const isOnline = parseInt(online);
    let cssClass = css.online;
    if (!isActive && isOnline) {
      cssClass = css.inactive;
    } else if (!isOnline) {
      cssClass = css.offline;
    }
    return cssClass;
  };

  render() {
    return (
      <div className={`${css.mote} ${this._timeUpdated ? css.new : css.old}`}>
        <div className={css.title}>
          <span
            className={`${css.led} ${this.ledStatus(
              this.props.data.location.mote_id,
              this.props.active,
              this.props.online
            )}`}
          />
          <h3 className={css.titleHeading}>{this.props.data.location.mote_name}</h3>
          <span className={css.time}>{this.props.data.date_time.time}</span>
        </div>
        <div className={css.room}>
          <span className={css.label}>Location:</span>{' '}
          {this.props.data.location.room_name}
        </div>
        <div className={css.message}>
          <span className={css.label}>Alert Type:</span> {this.props.alert}
        </div>
        {this.props.data.data && this.props.data.data.temp && (
          <Temp temp={this.props.data.data.temp} color="#20a8d8" />
        )}
        {this.props.data.data && this.props.data.data.light > 0 && (
          <Light light={this.props.data.data.light} color="#4dbd74" />
        )}
        {this.props.data.data && this.props.data.data.humidity > 0 && (
          <Humidity humidity={this.props.data.data.humidity} color="#ffc107" />
        )}
        {this.props.data.data && this.props.data.data.electrical_current > 0 && (
          <Amps amps={this.props.data.data.electrical_current} color="#ffffff" />
        )}
      </div>
    );
  }
}

export default withAppContext(Mote);
