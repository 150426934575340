import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from '../SignIn/LoginForm.module.scss';
import { Form, InputGroup, Button, Container } from 'react-bootstrap';
import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return (
      <Container>
        <form onSubmit={this.onSubmit} className={css.form}>
          <h1>Change Password</h1>
          <Form.Group controlId="formGridPasswordOne">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={css.inputIcon}
                  id="inputGroupPrepend"
                >
                  <FontAwesomeIcon
                    className={css.lockIcon}
                    icon={['far', 'lock-alt']}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                className={css.input}
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="New Password"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a password.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formGridPasswordTwo">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={css.inputIcon}
                  id="inputGroupPrepend"
                >
                  <FontAwesomeIcon
                    className={css.lockIcon}
                    icon={['far', 'lock-alt']}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                className={css.input}
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Confirm New Password"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please confirm your password.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          
          <Button disabled={isInvalid} className="px-4 btn btn-primary" type="submit">
            Change My Password
          </Button>

          {error && <p>{error.message}</p>}
        </form>
      </Container>
    );
  }
}

export default withFirebase(PasswordChangeForm);