import React, { Component } from 'react';
import css from './Header.module.scss';
import logo from '../../assets/logo.svg';
import NavOpen from './NavOpen';
import UserMenu from './UserMenu';
import SitesMenu from './SitesMenu';

class Header extends Component {
  render() {
    return (
      <header className={css.header}>
        <div className={css.headerSearch}>
          <NavOpen
            navOpen={this.props.navOpen}
            setNavOpen={this.props.setNavOpen}
          />
          <div
            className={css.brand}
            onClick={() => this.props.setNavOpen(true)}
          >
            <img src={logo} className={css.logo} alt="logo" />
          </div>
        </div>
        <UserMenu />
        <SitesMenu />
      </header>
    );
  }
}

export default Header;
