import React, { Component } from 'react';
import moment from 'moment';
import css from './Event.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Event extends Component {
  _isMounted = false;
  state = {
    now: null,
    eventType: [
      { name: 'Motion Start', id: '61', icon: 'running' },
      { name: 'Motion Stopped', id: '62', icon: 'running' },
      { name: 'Door Open', id: '77', icon: 'door-open' },
      {
        name: 'Door Closed',
        id: '78',
        icon: 'door-closed'
      },
      {
        name: 'Lights On',
        id: '64',
        icon: 'lightbulb-on'
      },
      { name: 'Lights Off', id: '65', icon: 'lightbulb' },
      {
        name: 'Panic',
        id: '59',
        icon: 'exclamation-triangle'
      },
      { name: 'Power On', id: '71', icon: 'power-off' },
      { name: 'Power Off', id: '72', icon: 'power-off' }
    ]
  };
  
  timer() {
    this.setState({
      now: moment().unix()
    })
  }

  componentDidMount() {
    this._isMounted = true;
    this.intervalId = setInterval(this.timer.bind(this), 60000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalId);
  }

  render() {
    const eventType = this.state.eventType.find(x => x.id === this.props.id);
    // console.log('ID: ', this.props.id);
    // console.log('Name: ', this.props.name);
    // console.log('Time: ', this.props.time);
    // console.log('Room: ', this.props.room);
    // console.log('Mote: ', this.props.mote);
    const unixSec = this.props.unix;
    let unixNowSec = this.state.now ? this.state.now : moment().unix();
    let unixDiffSec = unixNowSec - unixSec;
    let tempClass = css.hot;
    //console.log(`${unixNowSec} - ${unixSec} = ${unixDiffSec}`);
    
    if(unixDiffSec > 300 && unixDiffSec <= 600) {
      tempClass = css.medium;
    }
    else if(unixDiffSec > 600 && unixDiffSec <= 900) {
      tempClass = css.mild;
    }
    else if(unixDiffSec > 900) {
      tempClass = css.cold;
    }
    const iconName = eventType ? eventType.icon : 'running';

    return (
      <div className={`${css.event} ${tempClass}`}>
        <div className={css.icon}>
          <FontAwesomeIcon icon={['fa', iconName]} size="2x" fixedWidth />
        </div>
        <div className={css.info}>
          <div className={css.time}>{this.props.time}</div>
          <div className={css.room}>{this.props.room}  ({this.props.mote})</div>
          <div className={css.name}>
            {this.props.name}
          </div>
        </div>
      </div>
    );
  }
}

export default Event;
