import React, { Component } from 'react';
import AppProvider from './context/ContextProvider';
import './fontawesome';
import './App.scss';
import AppRouter from './routers/AppRouter';

class App extends Component {
  render() {
    return (
      <AppProvider>
        <AppRouter />
      </AppProvider>
    );
  }
}

export default App;
