import React, { Component } from 'react';
import { withAppContext } from '../../../../context/withAppContext';
import moment from 'moment/moment';
//import Moment from 'react-moment';
import css from '../Sensors.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';

class Temp extends Component {
  _isMounted = false;

  state = {
    open: false,
    labels: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    temp: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    min: 0,
    max: 100,
    step: 0.25,
    bg: 'rgba(255, 255, 255, .1)',
    border: '#ffffff'
  };

  chartReference = {};
  interval = null;

  componentDidMount() {
    this._isMounted = true;
    const isWholeNum = this.props.temp % 1 ? false : true;
    let safeMin = 0;
    let safeMax = 100;
    if (!isWholeNum) {
      const maxCheck = this.props.temp / 1;
      safeMin = Math.floor(maxCheck) * 1;
      safeMax = Math.ceil(maxCheck) * 1;
    } else {
      safeMin = this.props.temp - 0.5;
      safeMax = this.props.temp + 0.5;
    }
    this.setState({
      bg: this.hexToRgbA(this.props.color),
      border: this.props.color,
      min: safeMin,
      max: safeMax
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    const oldTemp = prevProps.temp;
    const newTemp = this.props.temp;
    if (oldTemp !== newTemp) {
      const isWholeNum = this.props.temp % 1 ? false : true;
      let safeMin = 0;
      let safeMax = 100;
      if (!isWholeNum) {
        const maxCheck = this.props.temp / 1;
        safeMin = Math.floor(maxCheck) * 1;
        safeMax = Math.ceil(maxCheck) * 1;
      } else {
        safeMin = this.props.temp - 0.5;
        safeMax = this.props.temp + 0.5;
      }
      this.setState({
        min: safeMin,
        max: safeMax
      });
    }
  }

  adddata = chartReference => {
    const { temp } = this.props;
    chartReference.props.data.datasets[0].data.shift();
    chartReference.props.data.labels.shift();
    this.setState({
      //amps: [...this.state.amps, Math.floor(Math.random() * 100 + 1)]
      temp: [...this.state.temp, temp]
    });

    const ctime = new Date();
    const csecs = moment(ctime).format('s');
    if (csecs % 15 === 0) {
      const label =
        csecs === '0'
          ? moment(ctime).format('mm:ss')
          : moment(ctime).format(':ss');
      this.setState({
        labels: [...this.state.labels, label]
      });
    } else {
      this.setState({
        labels: [...this.state.labels, '']
      });
    }
  };

  openChart = open => {
    if (!open) {
      this.interval = setInterval(() => {
        this.adddata(this.chartReference);
      }, 1000);
    } else {
      clearInterval(this.interval);
      this.setState({
        labels: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
        temp: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        ]
      });
    }
    this.setState({ open: !open });
  };

  hexToRgbA = hex => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return (
        'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)'
      );
    }
    throw new Error('Bad Hex');
  };

  render() {
    const width = window.innerWidth;
    const isDesktop = width < 750 ? false : true;
    const chartHeight = isDesktop ? 100 : 100;
    const mainChart = {
      labels: this.state.labels,
      datasets: [
        {
          label: 'amps',
          backgroundColor: this.state.bg,
          borderColor: this.state.border,
          pointHoverBackgroundColor: '#fff',
          borderWidth: 2,
          data: this.state.temp
        }
      ]
    };
    const mainChartOpts = {
      showLines: true,
      animation: false,
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: 'white',
              fontSize: 10,
              max: this.state.max,
              min: this.state.min,
              stepSize: this.state.step
            },
            gridLines: {
              drawTicks: false
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              drawTicks: false
            },
            ticks: {
              fontColor: 'white',
              fontSize: 10,
              maxRotation: 0,
              autoSkip: false,
              callback: function(value) {
                if (value.toString().length > 0) {
                  return value;
                } else {
                  return null;
                }
              }
            }
          }
        ]
      },
      elements: {
        line: {
          borderWidth: 1
        },
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4
        }
      }
    };
    const { open } = this.state;
    const chartIconClass = this.state.open ? css.open : css.closed;
    return (
      <>
        <button
          onClick={() => this.openChart(open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className={`${css.button} ${chartIconClass}`}
        >
          <span className={css.label}>Temperature:</span>
          <span className={css.value}>{this.props.temp}</span>
          <span className={css.units}>&deg;F</span>
          <span className={css.icon} style={{ color: this.state.border }}>
            <FontAwesomeIcon icon={['far', 'chart-line']} />
          </span>
        </button>
        <Collapse in={this.state.open} className={css.chart}>
          <div id="example-collapse-text">
            <Line
              data={mainChart}
              options={mainChartOpts}
              height={chartHeight}
              ref={reference => (this.chartReference = reference)}
            />
          </div>
        </Collapse>
      </>
    );
  }
}

export default withAppContext(Temp);
