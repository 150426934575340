import React, { Component } from 'react';
import { withFirebase } from '../../../Firebase';
import { withAppContext } from '../../../../context/withAppContext';
import ProgressBar from 'react-bootstrap/ProgressBar';
import css from './Room.module.scss';

class Room extends Component {
  _isMounted = false;
  rdbRef;
  state = { data: false };

  componentDidMount() {
    this._isMounted = true;
    const rdb = this.props.firebase.db;
    this.rdbRef = rdb
      .ref('motes/current')
      .orderByChild('location/site_id')
      .equalTo(this.props.context.state.activeSite + '')
    this.rdbRef.on('value', snapshot => {
      let logs = Object.values(snapshot.val());
      if (this._isMounted) {
        this.setValues(logs);
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.rdbRef) {
      this.rdbRef.off();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('prevState', prevState);
  }

  roomAverage = roomData => {
    if(roomData.length === 1) {
      return roomData[0].data;
    } else {
      let averager = [];
      for(let x=0; x<roomData.length; x++) {
        if(!roomData[x].data) continue; //skip over motes that have no data
        for (let dp in roomData[x].data) {
          averager[dp] = {'v': averager[dp] ? averager[dp].v + roomData[x][dp] : roomData[x].data[dp],
                          'c': roomData[dp] ? averager[dp].c++ : 1
                         }
        }
      }
      let averaged = [];
      for (const type in averager) {
        averaged[type] = averager[type].v / averager[type].c;
      }
      return averaged;
    }
  }

  setValues = log => {
    const { tid } = this.props;
    const justThisRoom = log.filter(
      log => log.location.room_id === tid
    );
    const data = justThisRoom.length
      ? this.roomAverage(justThisRoom)
      : false;
    this.setState({ data });
  };

  render() {
    return (
      <div className={css.card}>
        <div className={css.body}>
          <h5 className={css.title}>{this.props.name}</h5>
          {this.state.data && this.state.data.hasOwnProperty('temp') && (
            <div className={css.report}>
              <div className={css.value}>
                <span className={css.number}>
                  {Math.round(this.state.data.temp * 10) / 10}
                </span>
              </div>
              <div className={css.graph}>
                <span className={css.units}>&deg;F</span>
                <ProgressBar
                  className={`${css.bar} ${css.temp}`}
                  now={this.state.data.temp}
                />
              </div>
            </div>
          )}
          {this.state.data && this.state.data.hasOwnProperty('light') && (
            <div className={css.report}>
              <div className={css.value}>
                <span className={css.number}>
                  {Math.round(this.state.data.light * 10) / 10}
                </span>
              </div>
              <div className={css.graph}>
                <span className={css.units}>&#37; Light</span>
                <ProgressBar
                  className={`${css.bar} ${css.light}`}
                  now={this.state.data.light}
                />
              </div>
            </div>
          )}
          {this.state.data && this.state.data.hasOwnProperty('humidity') && (
            <div className={css.report}>
              <div className={css.value}>
                <span className={css.number}>
                  {Math.round(this.state.data.humidity * 10) / 10}
                </span>
              </div>
              <div className={css.graph}>
                <span className={css.units}>&#37; Humidity</span>
                <ProgressBar
                  className={`${css.bar} ${css.humidity}`}
                  now={this.state.data.humidity}
                />
              </div>
            </div>
          )}
          {this.state.data && this.state.data.hasOwnProperty('electrical_current') && (
            <div className={css.report}>
              <div className={css.value}>
                <span className={css.number}>
                  {Math.round(this.state.data.electrical_current * 10) / 10}
                </span>
              </div>
              <div className={css.graph}>
                <span className={css.units}>Amps</span>
                <ProgressBar
                  className={`${css.bar} ${css.amps}`}
                  now={this.state.data.electrical_current}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withFirebase(withAppContext(Room));
