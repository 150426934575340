import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from '../SignIn/LoginForm.module.scss';
import { Form, InputGroup, Button, Container } from 'react-bootstrap';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignUpPage = () => (
  <Container>
    <SignUpForm />
  </Container>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  phone: '',
  passwordOne: '',
  passwordTwo: '',
  error: null
}

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { username, email, phone, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            phone,
            roles: ['users'],
            sites: [{name: 'New User', tid:0}],
            defaultSite: 0,
            approved: 0
          });
      })
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.DASHBOARD)
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      username,
      email,
      phone,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;
    
    const isInvalid = 
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      phone === '' ||
      username === '';
    
    return (
      <form onSubmit={this.onSubmit}  className={css.form}>
        <h1>Sign Up</h1>
        <p className="text-muted">Create your account</p>
        <Form.Group controlId="validationCustomUsername">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className={css.inputIcon}
                id="inputGroupPrepend"
              >
                <FontAwesomeIcon
                  className={css.userIcon}
                  icon={['far', 'user']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="username"
              value={username}
              onChange={this.onChange}
              type="text"
              placeholder="Full Name"
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your full name.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="validationCustomUsername">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className={css.inputIcon}
                id="inputGroupPrepend"
              >
                <FontAwesomeIcon
                  className={css.userIcon}
                  icon={['far', 'envelope']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              placeholder="Email Address"
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your email
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      <Form.Group controlId="validationCustomPhone">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className={css.inputIcon}
                id="inputGroupPrepend"
              >
                <FontAwesomeIcon
                  className={css.userIcon}
                  icon={['far', 'phone']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="phone"
              value={phone}
              onChange={this.onChange}
              type="phone"
              placeholder="Phone Number"
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your phone number (used for notifications)
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formGridPassword">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className={css.inputIcon}
                id="inputGroupPrepend"
              >
                <FontAwesomeIcon
                  className={css.lockIcon}
                  icon={['far', 'lock-alt']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a password.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formGridPassword">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className={css.inputIcon}
                id="inputGroupPrepend"
              >
                <FontAwesomeIcon
                  className={css.lockIcon}
                  icon={['far', 'lock-alt']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please confirm your password.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Button disabled={isInvalid} className="px-4 btn btn-primary" type="submit">
          Sign Up
        </Button>

        {error && <p className="text-muted">{error.message}</p>}
      </form>
    );
  }
}

const SignUpLink = () => (
  <p className="text-muted">
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };