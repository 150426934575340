import React, { Component } from 'react';
import { withAppContext } from '../../context/withAppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './Dashboard.module.scss';
//import FilterMotes from '../Filters/FilterMotes';
import Room from './Widgets/Room/Room';
//import SnapShot from './Charts/SnapShots/SnapShot';

class Dashboard extends Component {
  componentDidMount() {
    //console.log("Mounted dashbaord");
  }
  
  render() {
//     let tempSnapShots = 'loading temp charts...';
//     let lightSnapShots = 'loading light charts...';
//     let humidSnapShots = 'loading humid charts...';

//     const roomList = this.props.context.state.rooms;
//     const roomActive = this.props.context.state.activeRoom;

//     const moteList = this.props.context.state.motes;
//     const moteActive = this.props.context.state.activeMote;

//     if (roomList) {
//       if (!roomActive) {
//         tempSnapShots = roomList.map(room => (
//           <SnapShot
//             key={`temp-${room.tid}`}
//             name={room.name}
//             rid={room.tid}
//             field="field_temperature"
//             color="blue"
//             units="&deg;F"
//           />
//         ));
//         lightSnapShots = roomList.map(room => (
//           <SnapShot
//             key={`light-${room.tid}`}
//             name={room.name}
//             rid={room.tid}
//             field="field_light_level"
//             color="green"
//             units="&#37; Light"
//           />
//         ));
//         humidSnapShots = roomList.map(room => (
//           <SnapShot
//             key={`humid-${room.tid}`}
//             name={room.name}
//             rid={room.tid}
//             field="field_humidity"
//             color="yellow"
//             units="&#37; Humidity"
//           />
//         ));
//       }
//     }

//     if (moteList) {
//       if (roomActive && !moteActive) {
//         tempSnapShots = moteList.map(mote => (
//           <SnapShot
//             key={`temp-${mote.tid}`}
//             name={mote.name}
//             rid={false}
//             mid={mote.tid}
//             field="field_temperature"
//             color="blue"
//             units="&deg;F"
//           />
//         ));
//         lightSnapShots = moteList.map(mote => (
//           <SnapShot
//             key={`light-${mote.tid}`}
//             name={mote.name}
//             rid={false}
//             mid={mote.tid}
//             field="field_light_level"
//             color="green"
//             units="&#37; Light"
//           />
//         ));
//         humidSnapShots = moteList.map(mote => (
//           <SnapShot
//             key={`humid-${mote.tid}`}
//             name={mote.name}
//             rid={false}
//             mid={mote.tid}
//             field="field_humidity"
//             color="yellow"
//             units="&#37; Humidity"
//           />
//         ));
//       } else if (roomActive && moteActive) {
//         tempSnapShots = (
//           <SnapShot
//             key={`temp-${moteActive}`}
//             name={moteList.find(x => x.tid === moteActive).name}
//             rid={false}
//             mid={moteActive}
//             field="field_temperature"
//             color="blue"
//             units="&deg;F"
//           />
//         );
//         lightSnapShots = (
//           <SnapShot
//             key={`light-${moteActive}`}
//             name={moteList.find(x => x.tid === moteActive).name}
//             rid={false}
//             mid={moteActive}
//             field="field_light_level"
//             color="green"
//             units="&#37; Light"
//           />
//         );
//         humidSnapShots = (
//           <SnapShot
//             key={`humid-${moteActive}`}
//             name={moteList.find(x => x.tid === moteActive).name}
//             rid={false}
//             mid={moteActive}
//             field="field_humidity"
//             color="yellow"
//             units="&#37; Humidity"
//           />
//         );
//       }
//     }
    return (
      <main className={css.main}>
        {this.props.context.state.loading && (
          <div className={css.loading}>
            <FontAwesomeIcon className={css.icon} icon={['fas', 'sync']} spin />
          </div>
        )}
        {!this.props.context.state.loading && (
          <React.Fragment>
            {/* <div className={css.settings}>
              <FilterMotes />
            </div> */}

            <div className={css.mainOverview}>
              {this.props.context.state.rooms.map(room => (
                <Room key={room.tid} tid={room.tid} name={room.name} />
              ))}
              {/* {roomList && tempSnapShots}
              {roomList && lightSnapShots}
              {roomList && humidSnapShots} */}
            </div>
          </React.Fragment>
        )}
      </main>
    );
  }
}

export default withAppContext(Dashboard);
