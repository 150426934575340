import React, { Component } from 'react';
import css from './Footer.module.scss';

class Footer extends Component {
  render() {
    return (
      <footer className={css.footer}>
        <div className={css.footerCopyright}>&copy; 2019 Monte</div>
        <div className={css.footerSignature}>vase.live</div>
      </footer>
    );
  }
}

export default Footer;
