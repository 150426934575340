import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAppContext } from '../../context/withAppContext';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          //console.log(authUser)
          this.props.history.push(ROUTES.SIGN_IN);
        } else {
          this.props.context.isLoading(true);
          //grab user's sites user info from firebase rtdb
          this.props.firebase.db
            .ref(`users/${authUser.uid}/sites`)
            .once('value')
            .then(snapshot => {
              snapshot.forEach(childSnapshot => {
                this.props.context.state.sites.push({
                  ...childSnapshot.val()
                });
              });
            })
            .catch(e => {
              console.log('Error fetching user sites', e);
            });
          //grab user's sites user info from firebase rtdb
          this.props.firebase.db
            .ref(`users/${authUser.uid}/roles`)
            .once('value')
            .then(snapshot => {
              snapshot.forEach(childSnapshot => {
                if (childSnapshot.val() === 'admin')
                  this.props.context.state.isAdmin = true;
              });
            })
            .catch(e => {
              console.log('Error fetching user roles', e);
            });
          //grab user's default site from firebase rtdb
          this.props.firebase.db
            .ref(`users/${authUser.uid}/defaultSite`)
            .once('value')
            .then(snapshot => {
              this.props.context.state.activeSite = snapshot.val();
              this.props.context.siteChange(snapshot.val());
            })
            .catch(e => {
              console.log('Error fetching default site', e);
            });
          //grab user's status from firebase rtdb
//           this.props.firebase.db
//             .ref(`users/${authUser.uid}/approved`)
//             .once('value')
//             .then(snapshot => {
//               this.props.context.state.approved = snapshot.val();
//             })
//             .catch(e => {
//               console.log('Error fetching user status', e);
//             });
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase,
    withAppContext
  )(WithAuthorization);
};

export default withAuthorization;
