import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAppContext } from '../../context/withAppContext';
import css from './Room.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from './Chart';

class Room extends Component {
  _isMounted = false;
  state = { chartOpen: false };

  componentDidMount() {
    this._isMounted = true;
    const rdb = this.props.firebase.db;
    const rdbRef = rdb
      .ref()
      .child('motes')
      .child('log');
    rdbRef.on('value', snapshot => {
      let logs = Object.values(snapshot.val());
      if (this._isMounted) {
        this.checkForValues(logs);
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {}

  checkForValues = log => {
    const siteID = this.props.context.state.activeSite + '';
    const { tid } = this.props;
    const justThisRoom = log.filter(
      log => log.location.site_id === siteID && log.location.room_id === tid
    );
    const justTemp = justThisRoom.filter(log =>
      log.data.hasOwnProperty('temp')
    );
    const tempCheck = justTemp.length > 0;
    const justLight = justThisRoom.filter(log =>
      log.data.hasOwnProperty('light')
    );
    const lightCheck = justLight.length > 0;
    const justHumidity = justThisRoom.filter(log =>
      log.data.hasOwnProperty('humidity')
    );
    const humidityCheck = justHumidity.length > 0;
    const justAmps = justThisRoom.filter(log =>
      log.data.hasOwnProperty('electrical_current')
    );
    const ampCheck = justAmps.length > 0;
    this.setState({ tempCheck, lightCheck, humidityCheck, ampCheck });
  };

  handleClick = (field, color, title) => {
    const { chartOpen } = this.state;
    if (!chartOpen) {
      this.setState({ chartOpen: true, field, color, title });
    } else {
      this.setState({ field, color, title });
    }
  };

  handleClose = () => {
    const { chartOpen } = this.state;
    if (chartOpen) {
      this.setState({ chartOpen: false, title: null });
    }
  };

  render() {
    return (
      <div className={css.room}>
        <div className={css.header}>
          <div className={css.titleWrapper}>
            <div className={css.title}>{this.props.name}</div>
            {this.state.title && (
              <div className={css.subtitle}>{this.state.title}</div>
            )}
          </div>
          <div className={css.icons}>
            {this.state.tempCheck && (
              <FontAwesomeIcon
                icon={['fas', 'thermometer-full']}
                mask={['fas', 'circle']}
                transform="shrink-4"
                size="2x"
                style={{ color: '#20a8d8' }}
                fixedWidth
                className={css.icon}
                onClick={() =>
                  this.handleClick(
                    'field_temperature',
                    '#20a8d8',
                    'Temperature'
                  )
                }
              />
            )}
            {this.state.lightCheck && (
              <FontAwesomeIcon
                icon={['fas', 'lightbulb']}
                mask={['fas', 'circle']}
                transform="shrink-5"
                size="2x"
                style={{ color: '#4dbd74' }}
                fixedWidth
                className={css.icon}
                onClick={() =>
                  this.handleClick(
                    'field_light_level',
                    '#4dbd74',
                    'Light Level'
                  )
                }
              />
            )}
            {this.state.humidityCheck && (
              <FontAwesomeIcon
                icon={['fas', 'humidity']}
                mask={['fas', 'circle']}
                transform="shrink-5"
                size="2x"
                style={{ color: '#ffc107' }}
                fixedWidth
                className={css.icon}
                onClick={() =>
                  this.handleClick('field_humidity', '#ffc107', 'Humidity')
                }
              />
            )}
            {this.state.ampCheck && (
              <FontAwesomeIcon
                icon={['fas', 'plug']}
                mask={['fas', 'circle']}
                transform="shrink-4"
                size="2x"
                style={{ color: '#ffffff' }}
                fixedWidth
                className={css.icon}
                onClick={() =>
                  this.handleClick(
                    'field_current',
                    '#ffffff',
                    'Electrical Current'
                  )
                }
              />
            )}
            {this.state.chartOpen && (
              <FontAwesomeIcon
                icon={['fas', 'times']}
                mask={['fas', 'circle']}
                transform="shrink-4"
                size="2x"
                style={{ color: '#73818f' }}
                fixedWidth
                className={css.icon}
                onClick={() => this.handleClose()}
              />
            )}
          </div>
        </div>
        {this.state.chartOpen && (
          <Chart
            rid={this.props.tid}
            name={this.props.name}
            color={this.state.color}
            field={this.state.field}
            start={this.props.start}
            end={this.props.end}
            points={this.props.points}
          />
        )}
      </div>
    );
  }
}

export default withFirebase(withAppContext(Room));
