import React, { Component } from 'react';
import { withAppContext } from '../../context/withAppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import css from './SitesMenu.module.scss';

class SitesMenu extends Component {
  state = {
    activeSite: this.props.context.state.activeSite
  };

  handleSelect = (evtKey, evt) => {
    this.setState({ activeSite: evtKey });
    this.props.context.siteChange(evtKey);
  };

  render() {
    return (
      <div className={css.locations}>
        <Dropdown>
          <Dropdown.Toggle as="div" id="dropdown-basic">
            <div className={css.location}>
              {this.props.context.state.activeSiteName}
            </div>
            <FontAwesomeIcon className={css.icon} icon={['far', 'globe']} />
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight={true} className={css.dropdown}>
            {this.props.context.state.sites.map(site => (
              <Dropdown.Item
                as="button"
                key={site.tid}
                eventKey={site.tid}
                onSelect={this.handleSelect}
                active={this.state.activeSite === site.tid}
                className="radio"
              >
                {site.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default withAppContext(SitesMenu);
