import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from '../../SignIn/LoginForm.module.scss';
import * as ROUTES from '../../../constants/routes';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { withFirebase } from '../../Firebase';
//import css from './Admin.module.scss';

class EditAccountPage extends Component {
  _isMounted = false;
  rdbRef;
  
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      user: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    
    this.rdbRef = this.props.firebase.db
      .ref(`users/${this.props.match.params.id}`);
    this.rdbRef
      .once('value')
      .then(snapshot => {
        this.setState({ user: snapshot.val() });
        ///eventually chain promise to get sites from fb, but for now set static
        this.setState({ sites: [
          {name: "Matt Home", tid: 42},
          {name: "Camp", tid: 41},
          {name: "Office", tid: 91},
          {name: "Marty's House", tid: 53},
          {name: "Mom's House", tid: 52},
          {name: "Neuvokas", tid: 101}
        ]});
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log('Error fetching user data', e);
      });
    this._isMounted = true;
  }
  
  componentWillUnmount() {
    this._isMounted = false;
    if(this.rdbRef) {
      this.rdbRef.off();
    }
  }

  render() {
    const { user, sites, loading } = this.state;

    return (
      <main>
        <h1>Edit Account</h1>

        {loading && <div>Loading ...</div>}

        {!loading && sites && <EditForm user={user} sites={sites} />}
      
      </main>
    );
  }
}

class EditFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      user: props.user,
      sites: props.sites,
      username: props.user.username,
      email: props.user.email,
      phone: props.user.phone,
      defaultSite: props.user.defaultSite,
      error: ''           
    };
  }

  onSubmit = event => {
    event.preventDefault();
    const { user } = this.state;
    
    //update user object with values from form (stored in state)
    user.username = this.state.username;
    user.phone = this.state.phone;
    user.email = this.state.email;
    user.defaultSite = parseInt(this.state.defaultSite);
    
    //clear sites before finding (new) sites
    user.sites = [];
    
    const regex = RegExp('^site-list-*');
    
    //find sites
    for (const prop in this.state) {
      if (regex.test(prop) && this.state[prop] !== null)
        user.sites.push({name: this.state[prop].name , tid: parseInt(this.state[prop].tid)})
    }

    //update on firebase
    this.props.firebase.db.ref(`users/${this.props.match.params.id}`).update(user).then(() => {
      this.props.history.push(ROUTES.ADMIN);
    }).catch((e) => {
      console.log('Error updating data', e);
    });
  };

  onChange = event => {
    if(event.target.name === 'userSites') {
      const key = 'site-list-'+event.target.value;
      if(event.target.checked) {
        this.setState({[key]: {name: event.target.dataset.name, tid: event.target.value}});
      } else {
        this.setState({[key]: null});
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  render() {
    const { user, sites, username, email, phone, defaultSite, error } = this.state;
    const isInvalid = username === '' || email === '' || defaultSite === '';

    return (
      <form onSubmit={this.onSubmit} className={css.form}>
        <h1>Edit Account</h1>
        <p className="text-muted">Update Details for {user.username}</p>
        <Form.Group controlId="validationCustomUsername">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className={css.inputIcon}
                id="inputGroupPrepend"
              >
                <FontAwesomeIcon
                  className={css.userIcon}
                  icon={['far', 'envelope']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              placeholder="Email Address"
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your email.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="validationCustomUsername">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className={css.inputIcon}
                id="inputGroupPrepend"
              >
                <FontAwesomeIcon
                  className={css.userIcon}
                  icon={['far', 'user']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="username"
              value={username}
              onChange={this.onChange}
              type="text"
              placeholder="Full Name"
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your full name.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="validationCustomPhone">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                className={css.inputIcon}
                id="inputGroupPrepend"
              >
                <FontAwesomeIcon
                  className={css.userIcon}
                  icon={['far', 'phone']}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={css.input}
              name="phone"
              value={phone}
              onChange={this.onChange}
              type="phone"
              placeholder="Phone Number"
              aria-describedby="inputGroupPrepend"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your phone number (used for notifications)
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        {sites.map(site => (
          <Form.Group  key={site.tid}>
            <Form.Check
              type="radio"
              inline
              name="defaultSite"
              onChange={this.onChange}
              value={site.tid}
              defaultChecked={user.defaultSite === site.tid}
            />
            <Form.Check
              inline
              name="userSites"
              className={`text-muted ${css.agree}`}
              onChange={this.onChange}
              value={site.tid}
              data-name={site.name}
              label={site.name}
              defaultChecked={!!user.sites.filter(usite => (usite.tid === site.tid)).length}
            />
          </Form.Group>
        ))}
        <Button disabled={isInvalid} className="px-4 btn btn-primary" type="submit">
          Save Account
        </Button>

        {error && <p className="text-muted">{error.message}</p>}
      </form>
    );
  }
}

const EditForm = compose(
  withRouter,
  withFirebase,
)(EditFormBase);



export default withFirebase(EditAccountPage);