import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase';

export const AppContext = React.createContext();

class AppProvider extends Component {
  state = {
    loading: true,
    navBarOpen: false,
    siteMenuOpen: false,
    sites: [],
    isAdmin: false,
    activeSite: null,
    activeSiteName: null,
    rooms: false,
    activeRoom: false,
    activeRoomName: 'tbd',
    motes: false,
    activeMote: false,
    offlineMotes: false,
    snapShots: {
      temp: {
        data: [0, 0, 0, 0, 0],
        labels: ['1', '2', '3', '4', '5'],
        points: 5,
        start: 1554630357,
        stop: 1554730357
      }
    },
    firebase: false,
    moteError: false,
    version: false,
    outdated: false,
    outdatedNotice: false,
    availableVersion: null
  };
  rdbRef;

  componentDidMount() {
    //keep up to date on new versions
    this.rdbRef = this.props.firebase.db.ref('app/version');
    this.rdbRef.on('value', snapshot => {
      this.handleVersionChange(snapshot.val());
    });
  }

  initializeData = (sid, name) => {
    this.setState({
      loading: true,
      activeSite: sid,
      activeSiteName: name,
      activeRoom: false,
      activeMote: false
    });
    
    //get rooms/motes from firebase
    this.props.firebase.db
      .ref(`sites/${sid}`)
      .once('value')
      .then(snapshot => {
        const data = snapshot.val();
        let roomsArray = [];
        let motesArray = [];
      
        for (const rid in data.rooms) {
          roomsArray.push({tid: rid, name: data.rooms[rid].name});
          for(const mid in data.rooms[rid].motes) {
            motesArray.push({ tid: mid, name: data.rooms[rid].motes[mid]});
          }
        }

        this.setState({
          rooms: roomsArray,
          motes: motesArray,
          loading: false
        });
      })
      .catch(e => {
        console.log('Error fetching site data', e);
      });
    
    //get current version
    this.props.firebase.db
      .ref(`app/version`)
      .once('value')
      .then(snapshot => {
        const version = snapshot.val();
        this.setState({ version });
      })
      .catch(e => {
        console.log('Error fetching app version', e);
      });
  };

  isLoading = status => {
    this.setState({ loading: status ? true : false });
  };

  handleVersionChange = newVersion => {
    if(!this.state.version) return; //only handle an a change, this will get called at the same time it loads the version for the first time which can create an error
    const newVersionArray = newVersion.split('.');
    const oldVersion = this.state.version.split('.');
    
    //check each part of verion and determine what to do
    if(oldVersion[0] < newVersionArray[0]) {
      this.setState({ outdated: true, outdatedNotice: true, availableVersion: newVersion });
    }
    if(oldVersion[1] < newVersionArray[1]) { //minior version update, show some toast!
      this.setState({ outdated: true, outdatedNotice: true, availableVersion: newVersion })
    }
    if(oldVersion[2] < newVersionArray[2]) { //patch version update, just refresh to get latest goodness!
      window.location.reload();
    }
  }
  
  handleCloseNotice = () => {
    console.log("Closing out of date notice");
    this.setState({ outdatedNotice: false });
  }

  handleSiteChange = sid => {
    sid = parseInt(sid);
    this.setState({ motes: [] });
    this.setState({ loading: true });
    //console.log('handleSiteChange - sites', this.state.sites);
    //console.log('handleSiteChange - sid', sid);
    const activeSite = this.state.sites.find(site => site.tid === sid);
    //console.log('handleSiteChange - activeSite', activeSite);
    this.initializeData(sid, activeSite.name);
  };

  handleRoomChange = event => {
    this.setActiveRoom(event.target.value);
  };

  setActiveRoom = rid => {
    if (rid !== 'all') {
      const rooms = this.state.rooms;
      const sid = this.state.activeSite;
      const activeRoomName = rooms.find(x => x.tid === rid).name;
      this.setState({ activeRoom: rid, activeRoomName });
      
      if(rid) {
        this.props.firebase.db
          .ref(`sites/${sid}/rooms/${rid}`)
          .once('value')
          .then(snapshot => {
            const data = snapshot.val();
            let motesArray = [];
            for(const mid in data.motes) {
              motesArray.push({ tid: mid, name: data.motes[mid]});
            }
            this.setState({ motes: motesArray });
          })
          .catch(e => {
            console.log("Unable get motes by room from firebase");
          });
      } else {
        this.props.firebase.db
          .ref(`sites/${sid}`)
          .once('value')
          .then(snapshot => {
            const data = snapshot.val();
            let motesArray = [];

            for (const rid in data.rooms) {
              for(const mid in data.rooms[rid].motes) {
                motesArray.push({ tid: mid, name: data.rooms[rid].motes[mid]});
              }
            }
            this.setState({ motes: motesArray });
          })
          .catch(e => {
            console.log('Error fetching site data', e);
          });
        
      }
    } else {
      this.setState({ activeRoom: false });
    }
    this.setState({ activeMote: false });
  };

  handleOpenNavBar = () => {
    this.setState({ navBarOpen: true });
  };

  handleCloseNavBar = () => {
    this.setState({ navBarOpen: false });
  };

  handleMoteChange = event => {
    this.setActiveMote(event.target.value);
  };

  setActiveMote = mid => {
    if (mid !== 'All') {
      this.setState({ activeMote: mid });
    } else {
      this.setState({ activeMote: false });
    }
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          isLoading: this.isLoading,
          openNavBar: this.handleOpenNavBar,
          closeNavBar: this.handleCloseNavBar,
          siteChange: this.handleSiteChange,
          roomChange: this.handleRoomChange,
          moteChange: this.handleMoteChange,
          closeNotice: this.handleCloseNotice,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default withFirebase(AppProvider);
