import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAppContext } from '../../context/withAppContext';
import moment from 'moment';
import css from './Floorplan.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Event from './Event.jsx';

class Floorplan extends Component {
  _isMounted = false;
  state = {
    now: null,
    history: [],
    motes: [
      { name: '002', id: '44', color: '#363d45' },
      { name: '004', id: '49', color: '#363d45' },
      { name: '005', id: '57', color: '#363d45' },
      { name: '006', id: '51', color: '#363d45' },
      { name: '007', id: '54', color: '#363d45' },
      { name: '008', id: '55', color: '#363d45' },
      { name: '009', id: '56', color: '#363d45' }
    ],
    colors: {
      44: '#363d45',
      49: '#363d45',
      57: '#363d45',
      51: '#363d45',
      54: '#363d45',
      55: '#363d45',
      56: '#363d45'
    }
  };
  
  timer() {
    this.setState({
      now: moment().unix()
    })
    this.setColors(this.state.history);
  }

  componentDidMount() {
    this._isMounted = true;
    this.intervalId = setInterval(this.timer.bind(this), 60000);

    const rdb = this.props.firebase.db;
    const rdbRef = rdb
      .ref('motes/floorplan/' + this.props.context.state.activeSite + '');
    rdbRef.orderByChild('date_time/unix_ms').limitToLast(20).on('value', snapshot => {
      if(snapshot.val()) {
        let logs = Object.values(snapshot.val());
        if (this._isMounted) {
          this.setState({ history: logs.reverse() });
          this.setColors(logs);
        }
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalId);
  }

  setStyles = id => {
    const mote = this.state.motes.find(f => f.id === id);
    return { fill: mote.color };
  };

  setColors = list => {
    //console.log('history', this.state.history);
    if (list.length > 0) {
      const defaultColors = {
        44: '#363d45',
        49: '#363d45',
        57: '#363d45',
        51: '#363d45',
        54: '#363d45',
        55: '#363d45',
        56: '#363d45'
      };
      this.setState({ colors: defaultColors });
      const mostRecent = list[0];
      const moteId = mostRecent.location.mote_id;
      const moteTime = mostRecent.date_time.unix;
      let unixNowSec = this.state.now ? this.state.now : moment().unix();
      let timeDiff = unixNowSec - moteTime;
      let moteColor = '#2079d8'; //hot
      if(timeDiff > 300 && timeDiff <= 600) {
        moteColor = '#3686db'; //medium
      }
      else if(timeDiff > 600 && timeDiff <= 900) {
        moteColor = '#4c93df'; //mild
      }
      else if(timeDiff > 900) {
        moteColor = '#000000'; //cold
      }
      const newColors = { ...this.state.colors, [moteId]: moteColor };
      this.setState({ colors: newColors });
    }
  };

  render() {
    let List;
    if (this.state.history.length > 0) {
      List = this.state.history.map((history, index) => (
        <Event
          key={index}
          id={history.event.id}
          name={history.event.name}
          time={history.date_time.time}
          unix={history.date_time.unix}
          room={history.location.room_name}
          mote={history.location.mote_name}
        />
      ));
    } else {
      List = (
        <div className={css.noResults}>
          <span className={css.noResultsIcon}>
            <FontAwesomeIcon icon={['fa', 'stopwatch']} size="3x" />
          </span>
          <span className={css.noResultsMessage}>
            It has been more then an hour since activity was last detected.
          </span>
        </div>
      );
    }
    return (
      <main className={css.main}>
        <div className={css.history}>
          {List}
        </div>
        <div className={css.floorplan}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 559.17 581.49"
            className={css.svg}
          >
            <g id="stairs">
              <rect
                className="cls-1"
                x="301.91"
                y="412.19"
                width="8.68"
                height="37.21"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="120.7"
                x2="301.91"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="83.49"
                x2="293.23"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="83.49"
                x2="293.23"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="120.7"
                x2="301.91"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="102.1"
                x2="301.91"
                y2="102.1"
              />
              <line
                className="cls-1"
                x1="297.57"
                y1="120.7"
                x2="297.57"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="120.7"
                x2="293.23"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="83.49"
                x2="284.54"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="83.49"
                x2="284.54"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="120.7"
                x2="293.23"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="102.1"
                x2="293.23"
                y2="102.1"
              />
              <line
                className="cls-1"
                x1="288.89"
                y1="120.7"
                x2="288.89"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="120.7"
                x2="284.54"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="83.49"
                x2="275.86"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="83.49"
                x2="275.86"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="120.7"
                x2="284.54"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="102.1"
                x2="284.54"
                y2="102.1"
              />
              <line
                className="cls-1"
                x1="280.2"
                y1="120.7"
                x2="280.2"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="120.7"
                x2="275.86"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="83.49"
                x2="267.18"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="83.49"
                x2="267.18"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="120.7"
                x2="275.86"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="102.1"
                x2="275.86"
                y2="102.1"
              />
              <line
                className="cls-1"
                x1="271.52"
                y1="120.7"
                x2="271.52"
                y2="83.49"
              />
              <rect
                className="cls-1"
                x="267.18"
                y="83.49"
                width="8.68"
                height="37.21"
              />
              <rect
                className="cls-1"
                x="275.86"
                y="83.49"
                width="8.68"
                height="37.21"
              />
              <rect
                className="cls-1"
                x="284.54"
                y="83.49"
                width="8.68"
                height="37.21"
              />
              <rect
                className="cls-1"
                x="293.23"
                y="83.49"
                width="8.68"
                height="37.21"
              />
              <rect
                className="cls-1"
                x="301.91"
                y="83.49"
                width="8.68"
                height="37.21"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="166.6"
                x2="232.45"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="166.6"
                x2="232.45"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="127.02"
                x2="223.77"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="127.02"
                x2="223.77"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="146.81"
                x2="232.45"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="228.11"
                y1="166.6"
                x2="228.11"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="166.6"
                x2="241.13"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="166.6"
                x2="241.13"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="127.02"
                x2="232.45"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="127.02"
                x2="232.45"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="146.81"
                x2="241.13"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="236.79"
                y1="166.6"
                x2="236.79"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="166.6"
                x2="249.81"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="166.6"
                x2="249.81"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="127.02"
                x2="241.13"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="127.02"
                x2="241.13"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="146.81"
                x2="249.81"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="245.47"
                y1="166.6"
                x2="245.47"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="166.6"
                x2="258.5"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="166.6"
                x2="258.5"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="127.02"
                x2="249.81"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="127.02"
                x2="249.81"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="146.81"
                x2="258.5"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="254.16"
                y1="166.6"
                x2="254.16"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="166.6"
                x2="267.18"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="166.6"
                x2="267.18"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="127.02"
                x2="258.5"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="127.02"
                x2="258.5"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="146.81"
                x2="267.18"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="262.84"
                y1="166.6"
                x2="262.84"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="166.6"
                x2="275.86"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="166.6"
                x2="275.86"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="127.02"
                x2="267.18"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="127.02"
                x2="267.18"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="146.81"
                x2="275.86"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="271.52"
                y1="166.6"
                x2="271.52"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="166.6"
                x2="284.54"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="166.6"
                x2="284.54"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="127.02"
                x2="275.86"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="127.02"
                x2="275.86"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="146.81"
                x2="284.54"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="280.2"
                y1="166.6"
                x2="280.2"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="166.6"
                x2="293.23"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="166.6"
                x2="293.23"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="127.02"
                x2="284.54"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="127.02"
                x2="284.54"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="146.81"
                x2="293.23"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="288.89"
                y1="166.6"
                x2="288.89"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="166.6"
                x2="301.91"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="166.6"
                x2="301.91"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="127.02"
                x2="293.23"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="127.02"
                x2="293.23"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="146.81"
                x2="301.91"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="297.57"
                y1="166.6"
                x2="297.57"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="166.6"
                x2="310.59"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="166.6"
                x2="310.59"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="127.02"
                x2="301.91"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="127.02"
                x2="301.91"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="146.81"
                x2="310.59"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="306.25"
                y1="166.6"
                x2="306.25"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="166.6"
                x2="232.45"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="166.6"
                x2="232.45"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="127.02"
                x2="223.77"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="127.02"
                x2="223.77"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="146.81"
                x2="232.45"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="228.11"
                y1="166.6"
                x2="228.11"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="166.6"
                x2="241.13"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="166.6"
                x2="241.13"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="127.02"
                x2="232.45"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="127.02"
                x2="232.45"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="146.81"
                x2="241.13"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="236.79"
                y1="166.6"
                x2="236.79"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="166.6"
                x2="249.81"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="166.6"
                x2="249.81"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="127.02"
                x2="241.13"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="127.02"
                x2="241.13"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="146.81"
                x2="249.81"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="245.47"
                y1="166.6"
                x2="245.47"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="166.6"
                x2="258.5"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="166.6"
                x2="258.5"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="127.02"
                x2="249.81"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="127.02"
                x2="249.81"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="146.81"
                x2="258.5"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="254.16"
                y1="166.6"
                x2="254.16"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="166.6"
                x2="267.18"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="166.6"
                x2="267.18"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="127.02"
                x2="258.5"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="127.02"
                x2="258.5"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="146.81"
                x2="267.18"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="262.84"
                y1="166.6"
                x2="262.84"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="166.6"
                x2="275.86"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="166.6"
                x2="275.86"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="127.02"
                x2="267.18"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="127.02"
                x2="267.18"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="146.81"
                x2="275.86"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="271.52"
                y1="166.6"
                x2="271.52"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="166.6"
                x2="284.54"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="166.6"
                x2="284.54"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="127.02"
                x2="275.86"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="127.02"
                x2="275.86"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="146.81"
                x2="284.54"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="280.2"
                y1="166.6"
                x2="280.2"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="166.6"
                x2="293.23"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="166.6"
                x2="293.23"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="127.02"
                x2="284.54"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="127.02"
                x2="284.54"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="146.81"
                x2="293.23"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="288.89"
                y1="166.6"
                x2="288.89"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="166.6"
                x2="301.91"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="166.6"
                x2="301.91"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="127.02"
                x2="293.23"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="127.02"
                x2="293.23"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="146.81"
                x2="301.91"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="297.57"
                y1="166.6"
                x2="297.57"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="166.6"
                x2="310.59"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="166.6"
                x2="310.59"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="127.02"
                x2="301.91"
                y2="127.02"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="127.02"
                x2="301.91"
                y2="166.6"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="146.81"
                x2="310.59"
                y2="146.81"
              />
              <line
                className="cls-1"
                x1="306.25"
                y1="166.6"
                x2="306.25"
                y2="127.02"
              />
              <rect
                className="cls-1"
                x="301.91"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="293.23"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="284.54"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="275.86"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="267.18"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="258.5"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="249.81"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="241.13"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="232.45"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="223.77"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="301.91"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="293.23"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="284.54"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="275.86"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="267.18"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="258.5"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="249.81"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="241.13"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="232.45"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="223.77"
                y="127.02"
                width="8.68"
                height="39.58"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="449.4"
                x2="310.59"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="412.19"
                x2="301.91"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="412.19"
                x2="301.91"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="449.4"
                x2="310.59"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="430.79"
                x2="310.59"
                y2="430.79"
              />
              <line
                className="cls-1"
                x1="306.25"
                y1="449.4"
                x2="306.25"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="449.4"
                x2="301.91"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="412.19"
                x2="293.23"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="412.19"
                x2="293.23"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="449.4"
                x2="301.91"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="430.79"
                x2="301.91"
                y2="430.79"
              />
              <line
                className="cls-1"
                x1="297.57"
                y1="449.4"
                x2="297.57"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="449.4"
                x2="293.23"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="412.19"
                x2="284.54"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="412.19"
                x2="284.54"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="449.4"
                x2="293.23"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="430.79"
                x2="293.23"
                y2="430.79"
              />
              <line
                className="cls-1"
                x1="288.89"
                y1="449.4"
                x2="288.89"
                y2="412.19"
              />
              <rect
                className="cls-1"
                x="223.77"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="232.45"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="241.13"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="249.81"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="258.5"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="267.18"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="275.86"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="284.54"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="293.23"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="301.91"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="223.77"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="232.45"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="241.13"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="249.81"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="258.5"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="267.18"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="275.86"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="284.54"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="293.23"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <rect
                className="cls-1"
                x="301.91"
                y="455.72"
                width="8.68"
                height="39.58"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="495.29"
                x2="310.59"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="495.29"
                x2="310.59"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="455.72"
                x2="301.91"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="455.72"
                x2="301.91"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="475.5"
                x2="310.59"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="306.25"
                y1="495.29"
                x2="306.25"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="495.29"
                x2="301.91"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="495.29"
                x2="301.91"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="455.72"
                x2="293.23"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="455.72"
                x2="293.23"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="475.5"
                x2="301.91"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="297.57"
                y1="495.29"
                x2="297.57"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="495.29"
                x2="293.23"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="495.29"
                x2="293.23"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="455.72"
                x2="284.54"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="455.72"
                x2="284.54"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="475.5"
                x2="293.23"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="288.89"
                y1="495.29"
                x2="288.89"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="495.29"
                x2="284.54"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="495.29"
                x2="284.54"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="455.72"
                x2="275.86"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="455.72"
                x2="275.86"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="475.5"
                x2="284.54"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="280.2"
                y1="495.29"
                x2="280.2"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="495.29"
                x2="275.86"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="495.29"
                x2="275.86"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="455.72"
                x2="267.18"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="455.72"
                x2="267.18"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="475.5"
                x2="275.86"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="271.52"
                y1="495.29"
                x2="271.52"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="495.29"
                x2="267.18"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="495.29"
                x2="267.18"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="455.72"
                x2="258.5"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="455.72"
                x2="258.5"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="475.5"
                x2="267.18"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="262.84"
                y1="495.29"
                x2="262.84"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="495.29"
                x2="258.5"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="495.29"
                x2="258.5"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="455.72"
                x2="249.81"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="455.72"
                x2="249.81"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="475.5"
                x2="258.5"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="254.16"
                y1="495.29"
                x2="254.16"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="495.29"
                x2="249.81"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="495.29"
                x2="249.81"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="455.72"
                x2="241.13"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="455.72"
                x2="241.13"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="475.5"
                x2="249.81"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="245.47"
                y1="495.29"
                x2="245.47"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="495.29"
                x2="241.13"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="495.29"
                x2="241.13"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="455.72"
                x2="232.45"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="455.72"
                x2="232.45"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="475.5"
                x2="241.13"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="236.79"
                y1="495.29"
                x2="236.79"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="495.29"
                x2="232.45"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="495.29"
                x2="232.45"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="455.72"
                x2="223.77"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="455.72"
                x2="223.77"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="475.5"
                x2="232.45"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="228.11"
                y1="495.29"
                x2="228.11"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="495.29"
                x2="310.59"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="495.29"
                x2="310.59"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="455.72"
                x2="301.91"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="455.72"
                x2="301.91"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="475.5"
                x2="310.59"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="306.25"
                y1="495.29"
                x2="306.25"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="495.29"
                x2="301.91"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="495.29"
                x2="301.91"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="455.72"
                x2="293.23"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="455.72"
                x2="293.23"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="475.5"
                x2="301.91"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="297.57"
                y1="495.29"
                x2="297.57"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="495.29"
                x2="293.23"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="495.29"
                x2="293.23"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="293.23"
                y1="455.72"
                x2="284.54"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="455.72"
                x2="284.54"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="475.5"
                x2="293.23"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="288.89"
                y1="495.29"
                x2="288.89"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="495.29"
                x2="284.54"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="495.29"
                x2="284.54"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="455.72"
                x2="275.86"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="455.72"
                x2="275.86"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="475.5"
                x2="284.54"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="280.2"
                y1="495.29"
                x2="280.2"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="495.29"
                x2="275.86"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="495.29"
                x2="275.86"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="455.72"
                x2="267.18"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="455.72"
                x2="267.18"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="475.5"
                x2="275.86"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="271.52"
                y1="495.29"
                x2="271.52"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="495.29"
                x2="267.18"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="495.29"
                x2="267.18"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="455.72"
                x2="258.5"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="455.72"
                x2="258.5"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="475.5"
                x2="267.18"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="262.84"
                y1="495.29"
                x2="262.84"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="495.29"
                x2="258.5"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="495.29"
                x2="258.5"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="258.5"
                y1="455.72"
                x2="249.81"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="455.72"
                x2="249.81"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="475.5"
                x2="258.5"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="254.16"
                y1="495.29"
                x2="254.16"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="495.29"
                x2="249.81"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="495.29"
                x2="249.81"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="249.81"
                y1="455.72"
                x2="241.13"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="455.72"
                x2="241.13"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="475.5"
                x2="249.81"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="245.47"
                y1="495.29"
                x2="245.47"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="495.29"
                x2="241.13"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="495.29"
                x2="241.13"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="241.13"
                y1="455.72"
                x2="232.45"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="455.72"
                x2="232.45"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="475.5"
                x2="241.13"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="236.79"
                y1="495.29"
                x2="236.79"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="495.29"
                x2="232.45"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="495.29"
                x2="232.45"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="232.45"
                y1="455.72"
                x2="223.77"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="455.72"
                x2="223.77"
                y2="495.29"
              />
              <line
                className="cls-1"
                x1="223.77"
                y1="475.5"
                x2="232.45"
                y2="475.5"
              />
              <line
                className="cls-1"
                x1="228.11"
                y1="495.29"
                x2="228.11"
                y2="455.72"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="120.7"
                x2="310.59"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="83.49"
                x2="301.91"
                y2="120.7"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="83.49"
                x2="301.91"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="310.59"
                y1="120.7"
                x2="310.59"
                y2="83.49"
              />
              <line
                className="cls-1"
                x1="301.91"
                y1="102.1"
                x2="310.59"
                y2="102.1"
              />
              <line
                className="cls-1"
                x1="306.25"
                y1="120.7"
                x2="306.25"
                y2="83.49"
              />
              <rect
                className="cls-1"
                x="293.23"
                y="412.19"
                width="8.68"
                height="37.21"
              />
              <rect
                className="cls-1"
                x="284.54"
                y="412.19"
                width="8.68"
                height="37.21"
              />
              <rect
                className="cls-1"
                x="275.86"
                y="412.19"
                width="8.68"
                height="37.21"
              />
              <rect
                className="cls-1"
                x="267.18"
                y="412.19"
                width="8.68"
                height="37.21"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="449.4"
                x2="275.86"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="412.19"
                x2="267.18"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="412.19"
                x2="267.18"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="449.4"
                x2="275.86"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="267.18"
                y1="430.79"
                x2="275.86"
                y2="430.79"
              />
              <line
                className="cls-1"
                x1="271.52"
                y1="449.4"
                x2="271.52"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="449.4"
                x2="284.54"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="412.19"
                x2="275.86"
                y2="449.4"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="412.19"
                x2="275.86"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="284.54"
                y1="449.4"
                x2="284.54"
                y2="412.19"
              />
              <line
                className="cls-1"
                x1="275.86"
                y1="430.79"
                x2="284.54"
                y2="430.79"
              />
              <line
                className="cls-1"
                x1="280.2"
                y1="449.4"
                x2="280.2"
                y2="412.19"
              />
            </g>
            <g id="upstairs">
              <line
                className="cls-2"
                x1="259.74"
                y1="412.3"
                x2="267.18"
                y2="412.3"
              />
              <line
                className="cls-2"
                x1="310.59"
                y1="77.4"
                x2="259.74"
                y2="77.4"
              />
              <line
                className="cls-2"
                x1="310.59"
                y1="33.13"
                x2="310.59"
                y2="77.4"
              />
              <line
                className="cls-2"
                x1="310.59"
                y1="2.98"
                x2="259.74"
                y2="2.98"
              />
              <line
                className="cls-2"
                x1="160.51"
                y1="127.02"
                x2="139.45"
                y2="127.02"
              />
              <line
                className="cls-2"
                x1="139.45"
                y1="101.42"
                x2="139.45"
                y2="127.02"
              />
              <polyline
                className="cls-2"
                points="102.66 166.6 0.5 166.6 0.5 251.05 160.51 251.05 160.51 166.6 139.99 166.6"
              />
              <polyline
                className="cls-2"
                points="99.73 122.54 99.73 101.42 0.5 101.42 0.5 166.1 99.73 166.1 99.73 159.42"
              />
              <polyline
                className="cls-2"
                points="139.45 101.42 160.51 101.42 160.51 2.98 0.5 2.98 0.5 101.42 102.11 101.42"
              />
              <polyline
                className="cls-2"
                points="208.91 127.02 259.74 127.02 259.74 2.98 160.51 2.98 160.51 127.02 171.57 127.02"
              />
              <polyline
                className="cls-2"
                points="171.57 166.6 160.51 166.6 160.51 251.05 259.74 251.05 259.74 166.6 208.91 166.6"
              />
            </g>
            <g id="main">
              <line
                className="cls-2"
                x1="300.67"
                y1="251.05"
                x2="259.74"
                y2="251.05"
              />
              <line
                className="cls-2"
                x1="360.21"
                y1="251.05"
                x2="337.91"
                y2="251.05"
              />
              <line
                className="cls-2"
                x1="288.26"
                y1="251.05"
                x2="288.26"
                y2="166.6"
              />
              <polyline
                className="cls-2"
                points="366.41 123.3 411.31 123.3 411.31 117.09 360.21 117.09 360.21 123.3"
              />
              <polyline
                className="cls-2"
                points="360.21 122.54 360.21 166.71 366.41 166.71 366.41 123.3"
              />
              <rect
                className="cls-2"
                x="449.51"
                y="3.46"
                width="6.2"
                height="29.28"
              />
              <polyline
                className="cls-2"
                points="558.66 251.05 558.66 2.98 360.21 2.98"
              />
              <rect
                className="cls-2"
                x="360.21"
                y="201.44"
                width="37.21"
                height="49.61"
              />
              <rect
                className="cls-2"
                x="310.59"
                y="77.4"
                width="54.58"
                height="6.2"
              />
              <rect
                className="cls-2"
                x="508.05"
                y="117.96"
                width="50.1"
                height="6.2"
              />
              <line
                className="cls-2"
                x1="360.21"
                y1="2.98"
                x2="310.59"
                y2="2.98"
              />
              <rect
                className="cls-2"
                x="310.59"
                y="2.98"
                width="37.21"
                height="30.15"
              />
              <polyline
                className="cls-2"
                points="440.58 33.13 449.07 33.13 449.07 2.98 348.6 2.98 348.6 33.13 440.58 33.13"
              />
              <line
                className="cls-2"
                x1="397.42"
                y1="251.05"
                x2="558.67"
                y2="251.97"
              />
            </g>
            <g id="laundry">
              <line
                className="cls-2"
                x1="160.51"
                y1="450.64"
                x2="160.51"
                y2="500.37"
              />
              <line
                className="cls-2"
                x1="267.18"
                y1="450.64"
                x2="267.18"
                y2="413.54"
              />
              <line
                className="cls-2"
                x1="160.51"
                y1="332.92"
                x2="160.51"
                y2="413.54"
              />
              <line
                className="cls-2"
                x1="259.74"
                y1="412.3"
                x2="259.74"
                y2="332.92"
              />
              <line
                className="cls-2"
                x1="160.51"
                y1="413.54"
                x2="160.51"
                y2="414.15"
              />
              <polyline
                className="cls-2"
                points="160.51 414.15 160.51 332.92 0.5 332.92 0.5 580.99 160.51 580.99 160.51 451.39"
              />
              <line
                className="cls-2"
                x1="206.4"
                y1="332.92"
                x2="160.51"
                y2="332.92"
              />
              <line
                className="cls-2"
                x1="259.74"
                y1="332.92"
                x2="243.64"
                y2="332.92"
              />
              <polyline
                className="cls-2"
                points="171.67 495.41 160.51 495.41 160.51 580.99 262.22 580.99 262.22 495.41 208.91 495.41"
              />
            </g>
            <g id="basement">
              <rect
                className="cls-2"
                x="310.59"
                y="330.44"
                width="248.07"
                height="248.08"
              />
            </g>
            <g id="labels">
              <g id="kitchen" className={css.label}>
                <path d="M330.53,51.84h1.1V60h-1.1ZM337.08,60h-1.34l-3.9-4.26V55.5l3.46-3.66h1.3L333,55.58Z" />
                <path d="M338.64,51.41a.65.65,0,0,1-.2-.47.7.7,0,0,1,.2-.5.69.69,0,0,1,.48-.2.68.68,0,0,1,.49.2.67.67,0,0,1,.21.5.62.62,0,0,1-.21.47.68.68,0,0,1-.49.2A.69.69,0,0,1,338.64,51.41Zm-.05,2.18h1.07V60h-1.07Z" />
                <path d="M345.6,59.76a2.49,2.49,0,0,1-1.34.39,1.92,1.92,0,0,1-1.53-.56,2.37,2.37,0,0,1-.49-1.6v-3.6h-1v-.45l1.12-.32.32-1.54h.65v1.51h1.92v.8h-1.92v3.43a2,2,0,0,0,.25,1.14,1,1,0,0,0,.82.35,2.09,2.09,0,0,0,.51-.08,3.63,3.63,0,0,0,.52-.23Z" />
                <path d="M348,59.75a2.8,2.8,0,0,1-1-1.23,4.13,4.13,0,0,1-.35-1.72,4,4,0,0,1,.36-1.71A2.83,2.83,0,0,1,348,53.84a3.24,3.24,0,0,1,1.79-.47,3.32,3.32,0,0,1,1.13.2,2.26,2.26,0,0,1,.88.56l-.39.75a2.23,2.23,0,0,0-.73-.49,2.16,2.16,0,0,0-.89-.19,1.91,1.91,0,0,0-1.57.71,3,3,0,0,0-.55,1.88,3.11,3.11,0,0,0,.52,1.89,1.71,1.71,0,0,0,1.47.7,2.19,2.19,0,0,0,1-.24,3.61,3.61,0,0,0,.87-.68l.4.68a3.08,3.08,0,0,1-1,.77,2.84,2.84,0,0,1-1.31.3A3,3,0,0,1,348,59.75Z" />
                <path d="M358.1,53.92a2.72,2.72,0,0,1,.4,1.61V60h-1.07V55.63a1.83,1.83,0,0,0-.24-1.08.94.94,0,0,0-.82-.33,2.11,2.11,0,0,0-.9.21,4.55,4.55,0,0,0-1,.62v5H353.4V50.4h1.07v3.8a3.72,3.72,0,0,1,2.17-.83A1.7,1.7,0,0,1,358.1,53.92Z" />
                <path d="M364.3,59.12a4,4,0,0,0,.92-.72l.41.67a3.59,3.59,0,0,1-1.08.84,2.91,2.91,0,0,1-1.34.3,3,3,0,0,1-1.7-.46,2.86,2.86,0,0,1-1-1.24,4.28,4.28,0,0,1-.34-1.71,4.43,4.43,0,0,1,.34-1.74,2.78,2.78,0,0,1,1-1.23,3,3,0,0,1,1.7-.46,2.83,2.83,0,0,1,1.25.28,2.11,2.11,0,0,1,.87.83,2.47,2.47,0,0,1,.31,1.27,4.73,4.73,0,0,1-.08.72h-4.33a2.16,2.16,0,0,0,0,.31,3.21,3.21,0,0,0,.51,1.9,1.73,1.73,0,0,0,1.5.7A2.16,2.16,0,0,0,364.3,59.12ZM362,54.57a2.16,2.16,0,0,0-.65,1.12h3.24v-.1a1.33,1.33,0,0,0-.42-1.06,1.47,1.47,0,0,0-1-.37A1.72,1.72,0,0,0,362,54.57Z" />
                <path d="M372.07,53.93a2.7,2.7,0,0,1,.4,1.6V60H371.4V55.63a1.83,1.83,0,0,0-.24-1.08,1,1,0,0,0-.83-.33,2.18,2.18,0,0,0-.9.21,4.76,4.76,0,0,0-1,.62v5h-1.07V53.59h.83l.15.67a3.78,3.78,0,0,1,2.26-.89A1.7,1.7,0,0,1,372.07,53.93Z" />
              </g>
              <g id="livingroom" className={css.label}>
                <path d="M440,223.66v.82h-5v-8.16h1.1v7.34Z" />
                <path d="M441.6,215.9a.62.62,0,0,1-.2-.48.66.66,0,0,1,.2-.49.69.69,0,0,1,.48-.2.68.68,0,0,1,.49.2.67.67,0,0,1,.21.49.63.63,0,0,1-.21.48.68.68,0,0,1-.49.2A.69.69,0,0,1,441.6,215.9Zm-.05,2.18h1.07v6.4h-1.07Z" />
                <path d="M450,218.08l-2.5,6.5h-1l-2.51-6.5h1.11L447,223.3l1.9-5.22Z" />
                <path d="M451.45,215.9a.66.66,0,0,1-.2-.48.68.68,0,0,1,.68-.69.72.72,0,0,1,.5.2.7.7,0,0,1,.2.49.66.66,0,0,1-.2.48.72.72,0,0,1-.5.2A.69.69,0,0,1,451.45,215.9Zm-.05,2.18h1.07v6.4H451.4Z" />
                <path d="M459.51,218.42a2.68,2.68,0,0,1,.4,1.6v4.46h-1.06v-4.36a1.82,1.82,0,0,0-.25-1.08.94.94,0,0,0-.82-.33,2.11,2.11,0,0,0-.9.21,4.55,4.55,0,0,0-1,.62v4.94h-1.07v-6.4h.83l.16.67a3.73,3.73,0,0,1,2.25-.89A1.69,1.69,0,0,1,459.51,218.42Z" />
                <path d="M467.58,218.88l-1.4-.13a2.21,2.21,0,0,1,.09,2.27,1.86,1.86,0,0,1-.79.78,2.58,2.58,0,0,1-1.3.3,3.42,3.42,0,0,1-.76-.09,2.91,2.91,0,0,0-.5.49.68.68,0,0,0-.15.42.39.39,0,0,0,.19.38,1.23,1.23,0,0,0,.62.12h2a2,2,0,0,1,1.37.42,1.41,1.41,0,0,1,.48,1.12,1.94,1.94,0,0,1-.46,1.26,3.14,3.14,0,0,1-1.29.89,4.8,4.8,0,0,1-1.82.33A3,3,0,0,1,462,227a1.48,1.48,0,0,1-.58-1.19,1.58,1.58,0,0,1,.25-.87,4.07,4.07,0,0,1,.78-.78,1,1,0,0,1-.72-1,1.21,1.21,0,0,1,.23-.69,3.84,3.84,0,0,1,.76-.75,1.79,1.79,0,0,1-.66-.73,2.22,2.22,0,0,1-.23-1,2.28,2.28,0,0,1,.26-1.06,2,2,0,0,1,.8-.77,2.74,2.74,0,0,1,1.3-.29,2.79,2.79,0,0,1,1.14.22h2.26Zm-2.12,5.4h-2.13a3.32,3.32,0,0,0-.67.7,1.26,1.26,0,0,0-.23.72.75.75,0,0,0,.38.7,2.14,2.14,0,0,0,1.09.22,3.58,3.58,0,0,0,1.3-.22,2.43,2.43,0,0,0,.93-.58,1,1,0,0,0,.33-.71C466.46,224.56,466.13,224.28,465.46,224.28Zm-.33-3.34a1.37,1.37,0,0,0,.35-1,1.32,1.32,0,0,0-.35-.95,1.42,1.42,0,0,0-1.91,0,1.32,1.32,0,0,0-.34.95,1.37,1.37,0,0,0,.34,1,1.27,1.27,0,0,0,.95.36A1.3,1.3,0,0,0,465.13,220.94Z" />
                <path d="M474.61,220.9h-1.49v3.58H472v-8.16h2.74a3.24,3.24,0,0,1,2.11.6,2,2,0,0,1,.72,1.65,2.16,2.16,0,0,1-.47,1.43,2.31,2.31,0,0,1-1.38.74l2.66,3.74h-1.28Zm-1.49-.84h1.64c1.15,0,1.72-.49,1.72-1.46a1.31,1.31,0,0,0-.44-1.06,1.88,1.88,0,0,0-1.28-.39h-1.64Z" />
                <path d="M480.8,224.24a2.7,2.7,0,0,1-1-1.23,4.16,4.16,0,0,1-.35-1.72,4.26,4.26,0,0,1,.35-1.74,2.7,2.7,0,0,1,1-1.23,3,3,0,0,1,1.71-.46,3,3,0,0,1,1.69.47,2.86,2.86,0,0,1,1,1.24,4.13,4.13,0,0,1,.34,1.7,4.18,4.18,0,0,1-.34,1.71,2.89,2.89,0,0,1-1,1.25,3,3,0,0,1-1.69.47A3,3,0,0,1,480.8,224.24Zm3.17-1.07a3.68,3.68,0,0,0,0-3.77,1.88,1.88,0,0,0-2.94,0,3.74,3.74,0,0,0,0,3.77,1.89,1.89,0,0,0,2.94,0Z" />
                <path d="M488.09,224.24a2.72,2.72,0,0,1-1-1.23,4.16,4.16,0,0,1-.34-1.72,4.25,4.25,0,0,1,.34-1.74,2.72,2.72,0,0,1,1-1.23,3.32,3.32,0,0,1,3.39,0,2.8,2.8,0,0,1,1,1.24,4.13,4.13,0,0,1,.34,1.7,4.18,4.18,0,0,1-.34,1.71,2.82,2.82,0,0,1-1,1.25,3.32,3.32,0,0,1-3.39,0Zm3.17-1.07a3.74,3.74,0,0,0,0-3.77,1.71,1.71,0,0,0-1.48-.71,1.68,1.68,0,0,0-1.47.71,3.74,3.74,0,0,0,0,3.77,1.7,1.7,0,0,0,1.47.7A1.73,1.73,0,0,0,491.26,223.17Z" />
                <path d="M503,218.41a2.88,2.88,0,0,1,.38,1.61v4.46h-1.07v-4.36a1.94,1.94,0,0,0-.22-1.08.85.85,0,0,0-.76-.33,1.9,1.9,0,0,0-.85.21,5.63,5.63,0,0,0-1,.63,2.59,2.59,0,0,1,0,.47v4.46h-1.07v-4.36a1.94,1.94,0,0,0-.22-1.08.85.85,0,0,0-.76-.33,1.86,1.86,0,0,0-.84.21,5,5,0,0,0-1,.62v4.94h-1.06v-6.4h.82l.16.67a3.65,3.65,0,0,1,2.17-.89,2,2,0,0,1,1.05.24,1.41,1.41,0,0,1,.56.7,3.72,3.72,0,0,1,2.24-.94A1.57,1.57,0,0,1,503,218.41Z" />
              </g>
              <g id="foyer" className={css.label}>
                <path d="M302.39,226.5v2.81h3.18v.84h-3.18v3.67h-1.1v-8.16h4.76v.84Z" />
                <path d="M308.32,233.58a2.89,2.89,0,0,1-1-1.23,4.57,4.57,0,0,1,0-3.46,2.75,2.75,0,0,1,1-1.24,3.06,3.06,0,0,1,1.7-.46,3,3,0,0,1,1.7.47,2.83,2.83,0,0,1,1,1.24,4.14,4.14,0,0,1,.35,1.7,4.2,4.2,0,0,1-.35,1.72,2.78,2.78,0,0,1-1,1.24,2.93,2.93,0,0,1-1.7.47A3.05,3.05,0,0,1,308.32,233.58Zm3.17-1.08a3.74,3.74,0,0,0,0-3.77A1.74,1.74,0,0,0,310,228a1.71,1.71,0,0,0-1.47.71,3.74,3.74,0,0,0,0,3.77,1.7,1.7,0,0,0,1.47.7A1.73,1.73,0,0,0,311.49,232.5Z" />
                <path d="M320,227.41l-2.78,7.29a3.65,3.65,0,0,1-1,1.6,2.22,2.22,0,0,1-1.46.49,2.65,2.65,0,0,1-.87-.14l.14-.84a2.74,2.74,0,0,0,.66.09,1.44,1.44,0,0,0,1-.36,3.21,3.21,0,0,0,.77-1.31l.1-.28L314,227.41h1.1l1.92,5.18,1.88-5.18Z" />
                <path d="M324.9,232.94a4,4,0,0,0,.93-.72l.41.67a3.59,3.59,0,0,1-1.08.84,2.92,2.92,0,0,1-1.35.3,3,3,0,0,1-1.7-.46,2.84,2.84,0,0,1-1-1.24,4.1,4.1,0,0,1-.34-1.7,4.19,4.19,0,0,1,.34-1.74,2.75,2.75,0,0,1,1-1.24,3,3,0,0,1,1.69-.46,2.75,2.75,0,0,1,1.26.29,2,2,0,0,1,.87.82,2.57,2.57,0,0,1,.31,1.27,3.87,3.87,0,0,1-.09.72h-4.33a2.55,2.55,0,0,0,0,.31,3.21,3.21,0,0,0,.51,1.9,1.72,1.72,0,0,0,1.49.7A2.16,2.16,0,0,0,324.9,232.94Zm-2.29-4.55a2.28,2.28,0,0,0-.66,1.12h3.24v-.1a1.37,1.37,0,0,0-.41-1.06,1.49,1.49,0,0,0-1-.37A1.69,1.69,0,0,0,322.61,228.39Z" />
                <path d="M331.31,228.2a3.15,3.15,0,0,0-1.1.2,4.43,4.43,0,0,0-1.17.73v4.69H328v-6.41h.82l.2.86a3.81,3.81,0,0,1,1.14-.83,2.92,2.92,0,0,1,1.17-.24Z" />
              </g>
              <g id="laundry-2" data-name="laundry" className={css.label}>
                <path d="M178,476.13V477h-5V468.8h1.11v7.33Z" />
                <path d="M184.82,477a2.57,2.57,0,0,1-.86.16,1.47,1.47,0,0,1-.79-.19.88.88,0,0,1-.37-.67,3.61,3.61,0,0,1-2.12.88,1.76,1.76,0,0,1-1.23-.44,1.43,1.43,0,0,1-.48-1.1,1.53,1.53,0,0,1,.33-1,2.56,2.56,0,0,1,.95-.67,13.56,13.56,0,0,1,1.63-.56l.89-.25v-.47c0-1-.36-1.44-1.09-1.44a2.5,2.5,0,0,0-1.1.24,4.24,4.24,0,0,0-1.14.92l-.45-.67a4,4,0,0,1,2.89-1.35,1.91,1.91,0,0,1,1.5.54,2.77,2.77,0,0,1,.46,1.81v3.13a.58.58,0,0,0,.1.38.38.38,0,0,0,.3.11,1.63,1.63,0,0,0,.51-.12Zm-4.54-.84a1,1,0,0,0,.63.19,2.1,2.1,0,0,0,.85-.2,6.59,6.59,0,0,0,1-.63V473.8l-.68.21a5.93,5.93,0,0,0-1.6.67,1,1,0,0,0-.45.83A.73.73,0,0,0,180.28,476.11Z" />
                <path d="M191.22,470.55V477h-.83l-.15-.67a3.78,3.78,0,0,1-2.26.89,1.7,1.7,0,0,1-1.46-.56,2.72,2.72,0,0,1-.4-1.6v-4.47h1.07v4.37a1.87,1.87,0,0,0,.24,1.08,1,1,0,0,0,.83.32,2.16,2.16,0,0,0,.9-.2,5.33,5.33,0,0,0,1-.63v-4.94Z" />
                <path d="M198.17,470.89a2.72,2.72,0,0,1,.41,1.6V477h-1.07v-4.37a1.87,1.87,0,0,0-.24-1.08,1,1,0,0,0-.83-.32,2.26,2.26,0,0,0-.9.2,4.89,4.89,0,0,0-1,.63V477h-1.07v-6.41h.83l.16.67a3.78,3.78,0,0,1,2.26-.89A1.67,1.67,0,0,1,198.17,470.89Z" />
                <path d="M205.75,467.36V477h-.83l-.12-.54a2.91,2.91,0,0,1-1.93.76,2.48,2.48,0,0,1-1.38-.4,2.54,2.54,0,0,1-1-1.13,4.4,4.4,0,0,1-.33-1.79,3.59,3.59,0,0,1,.89-2.64,3.25,3.25,0,0,1,2.38-.89,3.21,3.21,0,0,1,1.2.21v-3.18Zm-4,8.39a1.46,1.46,0,0,0,1.17.58,2.44,2.44,0,0,0,.89-.15,4.14,4.14,0,0,0,.85-.5v-4.31a3,3,0,0,0-1.15-.21,2,2,0,0,0-1.68.73,3.16,3.16,0,0,0-.55,2A3.07,3.07,0,0,0,201.77,475.75Z" />
                <path d="M211.34,471.34a3.06,3.06,0,0,0-1.09.2,4.31,4.31,0,0,0-1.18.73V477H208v-6.41h.83l.19.86a3.76,3.76,0,0,1,1.14-.82,3,3,0,0,1,1.17-.24Z" />
                <path d="M218,470.55l-2.79,7.3a3.67,3.67,0,0,1-1,1.59,2.21,2.21,0,0,1-1.46.5,2.31,2.31,0,0,1-.86-.15L212,479a2.35,2.35,0,0,0,.66.1,1.49,1.49,0,0,0,1-.36,3.38,3.38,0,0,0,.76-1.32l.1-.28L212,470.55h1.1l1.92,5.18,1.89-5.18Z" />
              </g>
              <g id="garage" className={css.label}>
                <path d="M65.62,469.73v3.35a4.27,4.27,0,0,1-1.22.46,7.25,7.25,0,0,1-1.67.18,4.16,4.16,0,0,1-2.27-.58,3.86,3.86,0,0,1-1.39-1.56,5,5,0,0,1-.46-2.13,4.67,4.67,0,0,1,.48-2.11,3.74,3.74,0,0,1,1.44-1.54,4.51,4.51,0,0,1,2.34-.57,4.17,4.17,0,0,1,1.51.26,3.39,3.39,0,0,1,1.19.72l-.45.73a2.9,2.9,0,0,0-1-.63,3.56,3.56,0,0,0-1.26-.23,3.2,3.2,0,0,0-1.71.44,2.9,2.9,0,0,0-1.08,1.22,4,4,0,0,0-.36,1.72,4.28,4.28,0,0,0,.34,1.76,2.77,2.77,0,0,0,2.67,1.65,6.42,6.42,0,0,0,1.82-.24v-2.9Z" />
                <path d="M72.93,473.53a2.69,2.69,0,0,1-.87.17,1.41,1.41,0,0,1-.78-.19.89.89,0,0,1-.38-.67,3.57,3.57,0,0,1-2.11.87,1.73,1.73,0,0,1-1.24-.44,1.4,1.4,0,0,1-.48-1.09,1.51,1.51,0,0,1,.34-1,2.51,2.51,0,0,1,.94-.66,13,13,0,0,1,1.64-.57l.89-.25v-.47c0-1-.37-1.44-1.1-1.44a2.43,2.43,0,0,0-1.1.25,4.35,4.35,0,0,0-1.14.92l-.44-.67A4,4,0,0,1,70,466.92a1.84,1.84,0,0,1,1.49.54,2.76,2.76,0,0,1,.46,1.8v3.13a.62.62,0,0,0,.1.38.35.35,0,0,0,.3.11,1.66,1.66,0,0,0,.52-.11Zm-4.55-.84a1,1,0,0,0,.64.19,1.91,1.91,0,0,0,.84-.2,7,7,0,0,0,1-.62v-1.67l-.69.2a5.64,5.64,0,0,0-1.59.68,1,1,0,0,0-.46.82A.73.73,0,0,0,68.38,472.69Z" />
                <path d="M77.62,467.93a2.94,2.94,0,0,0-1.1.2,4.46,4.46,0,0,0-1.17.72v4.69H74.28v-6.4h.83l.19.86a4,4,0,0,1,1.15-.83,2.92,2.92,0,0,1,1.17-.24Z" />
                <path d="M84.4,473.53a2.68,2.68,0,0,1-.86.17,1.45,1.45,0,0,1-.79-.19.92.92,0,0,1-.38-.67,3.57,3.57,0,0,1-2.11.87,1.74,1.74,0,0,1-1.24-.44,1.4,1.4,0,0,1-.48-1.09,1.57,1.57,0,0,1,.34-1,2.51,2.51,0,0,1,.94-.66,13.58,13.58,0,0,1,1.64-.57l.89-.25v-.47c0-1-.37-1.44-1.09-1.44a2.44,2.44,0,0,0-1.11.25,4.35,4.35,0,0,0-1.14.92l-.44-.67a4,4,0,0,1,2.89-1.35,1.84,1.84,0,0,1,1.49.54,2.76,2.76,0,0,1,.47,1.8v3.13a.62.62,0,0,0,.09.38.37.37,0,0,0,.3.11,1.66,1.66,0,0,0,.52-.11Zm-4.55-.84a1,1,0,0,0,.64.19,1.91,1.91,0,0,0,.84-.2,6.47,6.47,0,0,0,1-.62v-1.67l-.69.2a5.64,5.64,0,0,0-1.59.68,1,1,0,0,0-.46.82A.73.73,0,0,0,79.85,472.69Z" />
                <path d="M91.23,467.94l-1.41-.13a2.25,2.25,0,0,1,.1,2.27,1.92,1.92,0,0,1-.79.78,2.6,2.6,0,0,1-1.3.3,3.51,3.51,0,0,1-.77-.09,3.69,3.69,0,0,0-.49.49.68.68,0,0,0-.15.42.39.39,0,0,0,.19.38,1.21,1.21,0,0,0,.62.12h2a2,2,0,0,1,1.36.42,1.39,1.39,0,0,1,.49,1.12,2,2,0,0,1-.47,1.26,3,3,0,0,1-1.28.89,4.85,4.85,0,0,1-1.83.33,3,3,0,0,1-1.9-.48,1.45,1.45,0,0,1-.58-1.19,1.51,1.51,0,0,1,.25-.87,3.79,3.79,0,0,1,.78-.77,1,1,0,0,1-.72-1,1.21,1.21,0,0,1,.23-.69,3.58,3.58,0,0,1,.76-.75,1.79,1.79,0,0,1-.66-.73,2.06,2.06,0,0,1-.23-1,2.28,2.28,0,0,1,.26-1.06,1.92,1.92,0,0,1,.8-.77,2.74,2.74,0,0,1,1.3-.29,2.79,2.79,0,0,1,1.14.22h2.26Zm-2.13,5.4H87a3.08,3.08,0,0,0-.67.7,1.26,1.26,0,0,0-.23.72.75.75,0,0,0,.38.7,2.12,2.12,0,0,0,1.08.22,3.64,3.64,0,0,0,1.31-.22,2.51,2.51,0,0,0,.93-.58,1,1,0,0,0,.33-.71C90.11,473.62,89.78,473.34,89.1,473.34ZM88.77,470a1.48,1.48,0,0,0,0-1.92,1.42,1.42,0,0,0-1.91,0,1.32,1.32,0,0,0-.35.95,1.37,1.37,0,0,0,.35,1,1.43,1.43,0,0,0,1.9,0Z" />
                <path d="M96.06,472.67A4.32,4.32,0,0,0,97,472l.41.67a3.59,3.59,0,0,1-1.08.84,2.92,2.92,0,0,1-1.35.3,3,3,0,0,1-1.7-.46,2.84,2.84,0,0,1-1-1.24,4.11,4.11,0,0,1-.34-1.71,4.25,4.25,0,0,1,.34-1.74,2.78,2.78,0,0,1,1-1.23,3,3,0,0,1,1.69-.46,2.88,2.88,0,0,1,1.26.28,2.08,2.08,0,0,1,.87.82,2.61,2.61,0,0,1,.31,1.28,3.87,3.87,0,0,1-.09.72H93a2.55,2.55,0,0,0,0,.31,3.16,3.16,0,0,0,.51,1.89,1.71,1.71,0,0,0,1.49.71A2.16,2.16,0,0,0,96.06,472.67Zm-2.29-4.55a2.24,2.24,0,0,0-.66,1.12h3.24v-.1a1.36,1.36,0,0,0-.41-1.06,1.44,1.44,0,0,0-1-.37A1.69,1.69,0,0,0,93.77,468.12Z" />
              </g>
              <g id="basement-2" data-name="basement" className={css.label}>
                <path d="M420.45,440a2,2,0,0,1,.45,1.33,2,2,0,0,1-.76,1.64,3.42,3.42,0,0,1-2.19.61h-2.74v-8.16H418a2.91,2.91,0,0,1,1.92.55,1.84,1.84,0,0,1,.67,1.53,1.8,1.8,0,0,1-.36,1.11,1.82,1.82,0,0,1-1,.66A2.23,2.23,0,0,1,420.45,440Zm-4.13-3.75v2.63H418a1.61,1.61,0,0,0,1.1-.35,1.41,1.41,0,0,0,0-1.95,1.62,1.62,0,0,0-1.1-.33Zm3,6.1a1.58,1.58,0,0,0,0-2.27,2.15,2.15,0,0,0-1.37-.38h-1.63v3.05H418A2.07,2.07,0,0,0,419.32,442.39Z" />
                <path d="M427.91,443.61a3,3,0,0,1-.87.17,1.5,1.5,0,0,1-.78-.19.91.91,0,0,1-.38-.68,3.53,3.53,0,0,1-2.11.88,1.79,1.79,0,0,1-1.24-.44,1.43,1.43,0,0,1-.48-1.1,1.51,1.51,0,0,1,.34-1,2.52,2.52,0,0,1,.94-.67A15.11,15.11,0,0,1,425,440l.89-.25v-.47c0-1-.37-1.44-1.09-1.44a2.44,2.44,0,0,0-1.11.25,4.32,4.32,0,0,0-1.14.91l-.44-.67A4.05,4.05,0,0,1,425,437a1.84,1.84,0,0,1,1.49.54,2.76,2.76,0,0,1,.46,1.8v3.13a.62.62,0,0,0,.1.38.38.38,0,0,0,.3.11,1.74,1.74,0,0,0,.52-.12Zm-4.55-.84a1,1,0,0,0,.64.19,2.06,2.06,0,0,0,.84-.2,7.14,7.14,0,0,0,1-.63v-1.67l-.69.21a5.69,5.69,0,0,0-1.59.67,1,1,0,0,0-.46.83A.73.73,0,0,0,423.36,442.77Z" />
                <path d="M429.71,443.68a3.67,3.67,0,0,1-1-.45l.33-.78A3.31,3.31,0,0,0,431,443a1.68,1.68,0,0,0,1-.29.94.94,0,0,0,.39-.81.69.69,0,0,0-.19-.51,1.65,1.65,0,0,0-.47-.35,8.33,8.33,0,0,0-.8-.33,8.73,8.73,0,0,1-1-.43,1.9,1.9,0,0,1-.68-.55,1.47,1.47,0,0,1-.27-.91,1.61,1.61,0,0,1,.63-1.36,2.67,2.67,0,0,1,1.63-.47,3.53,3.53,0,0,1,2,.59l-.31.78a2.9,2.9,0,0,0-1.7-.55,1.56,1.56,0,0,0-.89.23.8.8,0,0,0-.34.7.78.78,0,0,0,.36.67,5.13,5.13,0,0,0,1.1.49,10.23,10.23,0,0,1,1,.44,1.94,1.94,0,0,1,.68.57,1.6,1.6,0,0,1,.28.95,1.74,1.74,0,0,1-.67,1.43,2.79,2.79,0,0,1-1.79.54A4.71,4.71,0,0,1,429.71,443.68Z" />
                <path d="M438.72,442.74a3.73,3.73,0,0,0,.92-.71l.41.67a3.68,3.68,0,0,1-1.07.84,3.26,3.26,0,0,1-3.05-.17,2.76,2.76,0,0,1-1-1.23,4.18,4.18,0,0,1-.34-1.71,4.25,4.25,0,0,1,.34-1.74,2.8,2.8,0,0,1,1-1.24,3,3,0,0,1,1.69-.45,2.7,2.7,0,0,1,1.25.28,2.08,2.08,0,0,1,.87.82,2.49,2.49,0,0,1,.31,1.27,3.57,3.57,0,0,1-.08.72h-4.33a2.73,2.73,0,0,0,0,.32,3.16,3.16,0,0,0,.51,1.89,1.72,1.72,0,0,0,1.49.71A2.16,2.16,0,0,0,438.72,442.74Zm-2.3-4.54a2.27,2.27,0,0,0-.65,1.11H439v-.09a1.31,1.31,0,0,0-.42-1.06,1.42,1.42,0,0,0-1-.37A1.72,1.72,0,0,0,436.42,438.2Z" />
                <path d="M450.2,437.55a2.86,2.86,0,0,1,.38,1.61v4.46h-1.07v-4.37a2,2,0,0,0-.22-1.08.88.88,0,0,0-.76-.32,1.94,1.94,0,0,0-.86.21,5.6,5.6,0,0,0-1,.63,2.77,2.77,0,0,1,0,.47v4.46h-1.06v-4.37a2.08,2.08,0,0,0-.22-1.08.89.89,0,0,0-.77-.32,2.06,2.06,0,0,0-.84.2,5.6,5.6,0,0,0-1,.63v4.94h-1.07v-6.41h.83l.16.67A3.6,3.6,0,0,1,445,437a1.93,1.93,0,0,1,1,.24,1.39,1.39,0,0,1,.57.69,3.76,3.76,0,0,1,2.24-.93A1.57,1.57,0,0,1,450.2,437.55Z" />
                <path d="M456.37,442.74a4,4,0,0,0,.93-.71l.4.67a3.55,3.55,0,0,1-1.07.84,3.26,3.26,0,0,1-3.05-.17,2.76,2.76,0,0,1-1-1.23,4.18,4.18,0,0,1-.34-1.71,4.25,4.25,0,0,1,.34-1.74,2.8,2.8,0,0,1,1-1.24,3,3,0,0,1,1.69-.45,2.7,2.7,0,0,1,1.25.28,2,2,0,0,1,.87.82,2.49,2.49,0,0,1,.32,1.27,3.69,3.69,0,0,1-.09.72H453.3a2.73,2.73,0,0,0,0,.32,3.16,3.16,0,0,0,.51,1.89,1.73,1.73,0,0,0,1.49.71A2.16,2.16,0,0,0,456.37,442.74Zm-2.3-4.54a2.27,2.27,0,0,0-.65,1.11h3.24v-.09a1.34,1.34,0,0,0-.41-1.06,1.45,1.45,0,0,0-1-.37A1.72,1.72,0,0,0,454.07,438.2Z" />
                <path d="M464.14,437.56a2.68,2.68,0,0,1,.4,1.6v4.46h-1.06v-4.37a1.87,1.87,0,0,0-.24-1.08,1,1,0,0,0-.83-.32,2.26,2.26,0,0,0-.9.2,5.06,5.06,0,0,0-1,.63v4.94h-1.06v-6.41h.82l.16.67a3.74,3.74,0,0,1,2.26-.88A1.65,1.65,0,0,1,464.14,437.56Z" />
                <path d="M470.34,443.38a2.41,2.41,0,0,1-1.34.4,2,2,0,0,1-1.53-.56,2.34,2.34,0,0,1-.49-1.6V438h-1v-.46l1.12-.31.32-1.55h.65v1.51H470V438h-1.92v3.43a2,2,0,0,0,.25,1.13.93.93,0,0,0,.82.36,2.09,2.09,0,0,0,.51-.09,2.25,2.25,0,0,0,.52-.23Z" />
              </g>
              <g id="masterbedroom" className={css.label}>
                <path d="M49.55,38.85V47H48.46V44.17l.06-3.53L46,44.38h-.76L42.72,40.6l.07,3.57V47H41.7V38.85h1l2.94,4.37,2.94-4.37Z" />
                <path d="M57.32,47a2.86,2.86,0,0,1-.86.17,1.56,1.56,0,0,1-.79-.19.9.9,0,0,1-.37-.68,3.55,3.55,0,0,1-2.12.88A1.76,1.76,0,0,1,52,46.74a1.43,1.43,0,0,1-.48-1.1,1.55,1.55,0,0,1,.33-1,2.56,2.56,0,0,1,1-.67,15.6,15.6,0,0,1,1.63-.56l.89-.25v-.47c0-1-.36-1.44-1.09-1.44a2.37,2.37,0,0,0-1.1.25,4,4,0,0,0-1.14.92l-.45-.68a4.07,4.07,0,0,1,2.89-1.34,1.88,1.88,0,0,1,1.5.54,2.76,2.76,0,0,1,.46,1.8v3.13a.56.56,0,0,0,.1.38.35.35,0,0,0,.3.11,1.63,1.63,0,0,0,.51-.12Zm-4.54-.84a1,1,0,0,0,.63.19,2.1,2.1,0,0,0,.85-.2,6.59,6.59,0,0,0,1-.63V43.86l-.68.2a6.27,6.27,0,0,0-1.6.67,1,1,0,0,0-.45.83A.73.73,0,0,0,52.78,46.16Z" />
                <path d="M59.12,47.07a3.61,3.61,0,0,1-1-.45l.32-.78a3.34,3.34,0,0,0,1.94.56,1.69,1.69,0,0,0,1-.29.94.94,0,0,0,.39-.81.76.76,0,0,0-.19-.51,1.57,1.57,0,0,0-.48-.35c-.19-.09-.45-.2-.8-.33a10.46,10.46,0,0,1-1-.43,1.87,1.87,0,0,1-.67-.55,1.47,1.47,0,0,1-.28-.91A1.61,1.61,0,0,1,59,40.86a2.69,2.69,0,0,1,1.63-.47,3.54,3.54,0,0,1,2,.59l-.31.78a2.9,2.9,0,0,0-1.71-.54,1.55,1.55,0,0,0-.88.22.79.79,0,0,0-.34.7.76.76,0,0,0,.36.67,4.57,4.57,0,0,0,1.09.49,8.55,8.55,0,0,1,1,.44,2,2,0,0,1,.68.57,1.53,1.53,0,0,1,.28.95,1.71,1.71,0,0,1-.67,1.43,2.77,2.77,0,0,1-1.79.54A4.33,4.33,0,0,1,59.12,47.07Z" />
                <path d="M68.14,46.77a2.45,2.45,0,0,1-1.35.4,1.93,1.93,0,0,1-1.52-.56,2.34,2.34,0,0,1-.49-1.6v-3.6h-1V41l1.11-.31.33-1.55h.64V40.6h1.92v.81H65.84v3.43A1.94,1.94,0,0,0,66.1,46a.91.91,0,0,0,.81.36,1.93,1.93,0,0,0,.51-.09,2.44,2.44,0,0,0,.52-.22Z" />
                <path d="M73.27,46.14a4.32,4.32,0,0,0,.93-.72l.4.67a3.55,3.55,0,0,1-1.07.84,3,3,0,0,1-1.35.3,3.06,3.06,0,0,1-1.7-.46,2.84,2.84,0,0,1-1-1.24,4.18,4.18,0,0,1-.34-1.71,4.25,4.25,0,0,1,.34-1.74,2.8,2.8,0,0,1,1-1.24,3,3,0,0,1,1.69-.45,2.7,2.7,0,0,1,1.25.28,2,2,0,0,1,.87.82,2.49,2.49,0,0,1,.32,1.27,3.69,3.69,0,0,1-.09.72H70.2a2.82,2.82,0,0,0,0,.32,3.16,3.16,0,0,0,.51,1.89,1.73,1.73,0,0,0,1.49.71A2.27,2.27,0,0,0,73.27,46.14ZM71,41.59a2.27,2.27,0,0,0-.65,1.11h3.24v-.09a1.34,1.34,0,0,0-.41-1.06,1.45,1.45,0,0,0-1-.37A1.72,1.72,0,0,0,71,41.59Z" />
                <path d="M79.68,41.4a3.14,3.14,0,0,0-1.1.19,4.65,4.65,0,0,0-1.17.73V47H76.34V40.6h.83l.19.87a4,4,0,0,1,1.15-.83,2.92,2.92,0,0,1,1.17-.24Z" />
                <path d="M46.94,57.83a2.17,2.17,0,0,1-.31,3,3.42,3.42,0,0,1-2.19.61H41.7V53.25h2.74a2.91,2.91,0,0,1,1.92.55A1.84,1.84,0,0,1,47,55.33a1.8,1.8,0,0,1-.36,1.11,1.85,1.85,0,0,1-1,.66A2.26,2.26,0,0,1,46.94,57.83ZM42.8,54.08v2.63h1.64a1.59,1.59,0,0,0,1.09-.35,1.43,1.43,0,0,0,0-2,1.64,1.64,0,0,0-1.1-.33Zm3,6.1a1.59,1.59,0,0,0,0-2.27,2.17,2.17,0,0,0-1.37-.37H42.8v3h1.64A2,2,0,0,0,45.8,60.18Z" />
                <path d="M52.69,60.54a4.32,4.32,0,0,0,.93-.72l.4.67a3.55,3.55,0,0,1-1.07.84,3,3,0,0,1-1.35.3,3.06,3.06,0,0,1-1.7-.46,2.84,2.84,0,0,1-1-1.24,4.18,4.18,0,0,1-.34-1.71,4.25,4.25,0,0,1,.34-1.74,2.8,2.8,0,0,1,1-1.24,3,3,0,0,1,1.69-.45,2.7,2.7,0,0,1,1.25.28,2,2,0,0,1,.87.82A2.49,2.49,0,0,1,54,57.16a3.69,3.69,0,0,1-.09.72H49.62a2.82,2.82,0,0,0,0,.32,3.16,3.16,0,0,0,.51,1.89,1.73,1.73,0,0,0,1.49.71A2.27,2.27,0,0,0,52.69,60.54ZM50.39,56a2.27,2.27,0,0,0-.65,1.11H53V57A1.34,1.34,0,0,0,52.57,56a1.45,1.45,0,0,0-1-.37A1.72,1.72,0,0,0,50.39,56Z" />
                <path d="M60.74,51.81v9.6h-.82l-.12-.54a2.89,2.89,0,0,1-1.94.76,2.44,2.44,0,0,1-2.32-1.53,4.19,4.19,0,0,1-.34-1.78,3.59,3.59,0,0,1,.89-2.64,3.21,3.21,0,0,1,2.39-.89,3.51,3.51,0,0,1,1.2.2V51.81Zm-4,8.39a1.44,1.44,0,0,0,1.17.59,2.19,2.19,0,0,0,.88-.16,4,4,0,0,0,.86-.5V55.82a3,3,0,0,0-1.16-.2,2,2,0,0,0-1.68.72,3.25,3.25,0,0,0-.55,2A3,3,0,0,0,56.77,60.2Z" />
                <path d="M66.34,55.8a3.1,3.1,0,0,0-1.1.19,4.65,4.65,0,0,0-1.17.73v4.69H63V55h.83l.19.87A3.72,3.72,0,0,1,65.17,55a2.92,2.92,0,0,1,1.17-.24Z" />
                <path d="M68.63,61.17a2.72,2.72,0,0,1-1-1.23,4.16,4.16,0,0,1-.34-1.72,4.25,4.25,0,0,1,.34-1.74,2.8,2.8,0,0,1,1-1.24,3.05,3.05,0,0,1,1.7-.45,2.88,2.88,0,0,1,1.69.47,2.8,2.8,0,0,1,1,1.24,4.1,4.1,0,0,1,.34,1.7,4.18,4.18,0,0,1-.34,1.71,2.82,2.82,0,0,1-1,1.25,3.32,3.32,0,0,1-3.39,0ZM71.8,60.1a3.76,3.76,0,0,0,0-3.78,1.73,1.73,0,0,0-1.48-.7,1.7,1.7,0,0,0-1.47.7,3.76,3.76,0,0,0,0,3.78,1.7,1.7,0,0,0,1.47.7A1.73,1.73,0,0,0,71.8,60.1Z" />
                <path d="M75.91,61.17a2.78,2.78,0,0,1-1-1.23,4.33,4.33,0,0,1-.34-1.72,4.43,4.43,0,0,1,.34-1.74,2.86,2.86,0,0,1,1-1.24,3.09,3.09,0,0,1,1.71-.45,2.92,2.92,0,0,1,1.69.47,2.86,2.86,0,0,1,1,1.24,4.26,4.26,0,0,1,.34,1.7,4.35,4.35,0,0,1-.34,1.71,2.89,2.89,0,0,1-1,1.25,3.34,3.34,0,0,1-3.4,0Zm3.17-1.07a3.69,3.69,0,0,0,0-3.78,1.72,1.72,0,0,0-1.48-.7,1.7,1.7,0,0,0-1.47.7,3.76,3.76,0,0,0,0,3.78,1.7,1.7,0,0,0,1.47.7A1.72,1.72,0,0,0,79.08,60.1Z" />
                <path d="M90.79,55.34A2.86,2.86,0,0,1,91.16,57v4.46H90.1V57A2,2,0,0,0,89.87,56a.86.86,0,0,0-.76-.32,1.94,1.94,0,0,0-.85.21,5.6,5.6,0,0,0-1,.63c0,.1,0,.25,0,.47v4.46H86.24V57A2,2,0,0,0,86,56a.87.87,0,0,0-.76-.32,2.06,2.06,0,0,0-.84.2,5.6,5.6,0,0,0-1,.63v4.94H82.38V55h.83l.15.68a3.61,3.61,0,0,1,2.18-.89,2,2,0,0,1,1,.24,1.37,1.37,0,0,1,.56.69,3.76,3.76,0,0,1,2.25-.93A1.59,1.59,0,0,1,90.79,55.34Z" />
              </g>
            </g>
            <g id="doors">
              <rect
                className="cls-2"
                x="171.57"
                y="124.54"
                width="37.33"
                height="6.82"
              />
              <rect
                className="cls-2"
                x="102.11"
                y="98.49"
                width="37.33"
                height="6.82"
              />
              <rect
                className="cls-2"
                x="96.28"
                y="123.66"
                width="6.82"
                height="35.76"
              />
              <rect
                className="cls-2"
                x="171.57"
                y="162.99"
                width="37.33"
                height="6.82"
              />
              <rect
                className="cls-2"
                x="102.66"
                y="162.99"
                width="37.33"
                height="6.82"
              />
              <rect
                className="cls-2"
                x="459.44"
                y="0.5"
                width="37.24"
                height="6.2"
              />
              <rect
                className="cls-2"
                x="459.44"
                y="0.5"
                width="37.24"
                height="6.2"
              />
              <line
                className="cls-2"
                x1="496.67"
                y1="6.7"
                x2="496.67"
                y2="6.7"
              />
              <rect
                className="cls-2"
                x="300.67"
                y="248.57"
                width="37.24"
                height="6.2"
              />
              <rect
                className="cls-2"
                x="497.89"
                y="0.5"
                width="37.24"
                height="6.2"
              />
              <rect
                className="cls-2"
                x="497.89"
                y="0.5"
                width="37.24"
                height="6.2"
              />
              <line
                className="cls-2"
                x1="497.89"
                y1="6.7"
                x2="497.89"
                y2="6.7"
              />
              <rect
                className="cls-2"
                x="171.67"
                y="492.93"
                width="37.24"
                height="6.2"
              />
              <rect
                className="cls-2"
                x="156.18"
                y="414.15"
                width="6.2"
                height="37.24"
              />
              <rect
                className="cls-2"
                x="206.4"
                y="330.44"
                width="37.24"
                height="6.2"
              />
            </g>
            <g id={css.mote9}>
              <polygon
                className={css.vase}
                style={{ fill: this.state.colors[56] }}
                points="179.11 397.42 170.47 392.38 161.79 397.34 161.75 407.34 170.39 412.38 179.07 407.42 179.11 397.42"
              />
              <g className={css.id}>
                <path d="M172.58,398.5a3.48,3.48,0,0,1,.77,2.42,4.81,4.81,0,0,1-1.27,3.38,6.67,6.67,0,0,1-3.51,1.85l-.24-.8a6.58,6.58,0,0,0,2.52-1.2,3.62,3.62,0,0,0,1.25-2,2.91,2.91,0,0,1-1.81.64,2.67,2.67,0,0,1-1.38-.35,2.45,2.45,0,0,1-.9-.91,2.49,2.49,0,0,1-.3-1.18A2.76,2.76,0,0,1,168,399a2.5,2.5,0,0,1,1-1,3,3,0,0,1,1.51-.37A2.65,2.65,0,0,1,172.58,398.5Zm-.34,2.42v-.09a2.8,2.8,0,0,0-.46-1.74,1.49,1.49,0,0,0-1.25-.6,1.59,1.59,0,0,0-1.27.52,1.87,1.87,0,0,0-.45,1.28,1.68,1.68,0,0,0,.42,1.17,1.43,1.43,0,0,0,1.13.47A2.22,2.22,0,0,0,172.24,400.92Z" />
              </g>
              <path
                className={css.area}
                style={{ fill: this.state.colors[56] }}
                d="M212.73,350.42l-33.48,48.07v8l32.18,48.74C250.31,431.42,251,375.19,212.73,350.42Z"
              />
              <path
                id="light"
                className="cls-2"
                d="M179.07,406l0-7.1c2.51,0,3.54,1.05,3.53,3.56S181.59,406,179.07,406Z"
              />
            </g>
            <g id={css.mote8}>
              <polygon
                className={css.vase}
                style={{ fill: this.state.colors[55] }}
                points="438.43 34.63 446.64 28.93 445.82 18.96 436.77 14.7 428.56 20.39 429.38 30.36 438.43 34.63"
              />
              <g className={css.id}>
                <path d="M439.73,25a1.87,1.87,0,0,1,.44,1.29,2.06,2.06,0,0,1-.7,1.61,3,3,0,0,1-2.08.63,3.62,3.62,0,0,1-1.56-.3,2.12,2.12,0,0,1-.94-.79,2,2,0,0,1-.31-1.08,2.38,2.38,0,0,1,1.65-2.2,2.69,2.69,0,0,1-1.07-.83,1.82,1.82,0,0,1-.34-1.12,2.18,2.18,0,0,1,.29-1.1,2.08,2.08,0,0,1,.87-.78,3.14,3.14,0,0,1,1.41-.28,3.35,3.35,0,0,1,1.41.27,2,2,0,0,1,.85.74,2.06,2.06,0,0,1-.08,2.2,3,3,0,0,1-1.16.83A3.61,3.61,0,0,1,439.73,25Zm-1.07,2.3a1.31,1.31,0,0,0,.41-1,1.27,1.27,0,0,0-.46-1.05,5.06,5.06,0,0,0-1.41-.72,2.59,2.59,0,0,0-1.12.72,1.55,1.55,0,0,0-.39,1.05,1.3,1.3,0,0,0,.42,1,2.26,2.26,0,0,0,2.55,0Zm-2.37-6.05a1.27,1.27,0,0,0-.36.92,1.25,1.25,0,0,0,.4,1,3.3,3.3,0,0,0,1.16.64,2.4,2.4,0,0,0,1-.71,1.51,1.51,0,0,0,.31-1,1.19,1.19,0,0,0-.36-.87,1.46,1.46,0,0,0-1.08-.35A1.52,1.52,0,0,0,436.29,21.29Z" />
              </g>
              <path
                className={css.area}
                style={{ fill: this.state.colors[55] }}
                d="M372.18,37.51C375.87,83,425.74,109,465.11,86l-28-52-7-3.2Z"
              />
              <path
                id="light-2"
                data-name="light"
                className="cls-2"
                d="M437.11,34l-6.42-3c-1.07,2.27-.57,3.65,1.7,4.73S436,36.28,437.11,34Z"
              />
            </g>
            <g id={css.mote7}>
              <polygon
                className={css.vase}
                style={{ fill: this.state.colors[54] }}
                points="326 523.22 320.96 531.86 325.92 540.54 335.92 540.58 340.96 531.95 336 523.26 326 523.22"
              />
              <g className={css.id}>
                <path d="M333.35,528.69v0h-4.67v-.84h5.87v.67L330.3,536H329Z" />
              </g>
              <path
                className={css.area}
                style={{ fill: this.state.colors[54] }}
                d="M310.59,464.77a61.92,61.92,0,0,1,74.23,27.84l-49.39,30.65-8.81,0-16-12.56Z"
              />
              <path
                id="light-3"
                data-name="light"
                className="cls-2"
                d="M334.55,523.26l-7.1,0c0-2.52,1.06-3.55,3.57-3.54S334.56,520.75,334.55,523.26Z"
              />
            </g>
            <g id={css.mote6}>
              <polygon
                className={css.vase}
                style={{ fill: this.state.colors[51] }}
                points="139.66 24.21 137.12 14.54 127.47 11.91 120.37 18.95 122.91 28.62 132.56 31.25 139.66 24.21"
              />
              <g className={css.id}>
                <path d="M131.47,20.65a2.29,2.29,0,0,1,.89.91,2.36,2.36,0,0,1,.31,1.17,2.7,2.7,0,0,1-.34,1.34,2.47,2.47,0,0,1-1,1,2.88,2.88,0,0,1-1.5.37,2.63,2.63,0,0,1-2-.85,3.47,3.47,0,0,1-.77-2.41,4.71,4.71,0,0,1,1.28-3.38,6.61,6.61,0,0,1,3.51-1.86l.24.8a6.83,6.83,0,0,0-2.54,1.2,3.66,3.66,0,0,0-1.24,2,2.49,2.49,0,0,1,.84-.48,2.64,2.64,0,0,1,1-.17A2.58,2.58,0,0,1,131.47,20.65Zm-.35,3.41a1.87,1.87,0,0,0,.44-1.29,1.67,1.67,0,0,0-.41-1.17,1.43,1.43,0,0,0-1.13-.46,2.24,2.24,0,0,0-1.89,1v.07A2.8,2.8,0,0,0,128.6,24a1.48,1.48,0,0,0,1.25.6A1.6,1.6,0,0,0,131.12,24.06Z" />
              </g>
              <path
                className={css.area}
                style={{ fill: this.state.colors[51] }}
                d="M138.43,25,160.51,20V84.65a58.52,58.52,0,0,1-36,3.92l9-58.34Z"
              />
              <path
                id="light-4"
                data-name="light"
                className="cls-2"
                d="M138.63,25.23l-5,5c1.77,1.78,3.24,1.79,5,0S140.4,27,138.63,25.23Z"
              />
            </g>
            <g id={css.mote5}>
              <polygon
                className={css.vase}
                style={{ fill: this.state.colors[57] }}
                points="343.85 228.01 338.81 236.65 343.77 245.33 353.77 245.38 358.81 236.74 353.85 228.06 343.85 228.01"
              />
              <g className={css.id}>
                <path d="M350.06,235.64a2.29,2.29,0,0,1,1,.87,2.43,2.43,0,0,1,.34,1.27,2.68,2.68,0,0,1-.37,1.37,2.55,2.55,0,0,1-1.07,1,3.55,3.55,0,0,1-1.65.36,4.6,4.6,0,0,1-1.45-.22,2.87,2.87,0,0,1-1.13-.75l.37-.71a3.34,3.34,0,0,0,1,.65,3.26,3.26,0,0,0,1.17.19,2.1,2.1,0,0,0,1.45-.49,1.76,1.76,0,0,0,.53-1.34,1.6,1.6,0,0,0-.46-1.21,1.88,1.88,0,0,0-1.34-.44,5.36,5.36,0,0,0-1.95.42l-.29-.18.35-4.31h4.57V233h-3.59l-.2,2.55a4.36,4.36,0,0,1,1.18-.17A3.39,3.39,0,0,1,350.06,235.64Z" />
              </g>
              <path
                className={css.area}
                style={{ fill: this.state.colors[57] }}
                d="M360.21,201.44l9-31.88A61.92,61.92,0,0,0,298,192.75L345.3,228l7.87-.17,7-5.89Z"
              />
              <path
                id="light-5"
                data-name="light"
                className="cls-2"
                d="M352.4,228.05l-7.1,0c0-2.51,1.05-3.55,3.56-3.54S352.41,225.54,352.4,228.05Z"
              />
            </g>
            <g id={css.mote4}>
              <polygon
                className={css.vase}
                style={{ fill: this.state.colors[49] }}
                points="521.53 148.74 529.75 143.04 528.92 133.08 519.88 128.81 511.66 134.51 512.49 144.47 521.53 148.74"
              />
              <g className={css.id}>
                <path d="M523.33,140.67H522v2.19h-1.11v-2.19h-3.77V140L521,134.7h1v5.13h1.34Zm-2.45-.84v-1.5l0-2.21-1.49,2.13-1.15,1.58Z" />
              </g>
              <path
                className={css.area}
                style={{ fill: this.state.colors[49] }}
                d="M455.28,151.62c3.7,45.44,53.56,71.45,92.94,48.48l-28-52-7.14-3.18Z"
              />
              <path
                className="cls-2"
                d="M520.22,148.12l-6.42-3c-1.07,2.27-.58,3.66,1.69,4.73S519.15,150.4,520.22,148.12Z"
              />
            </g>
            <g id={css.mote2}>
              <polygon
                className={css.vase}
                style={{ fill: this.state.colors[44] }}
                points="130.02 364.64 135.06 356 130.1 347.32 120.1 347.28 115.06 355.92 120.02 364.6 130.02 364.64"
              />
              <g className={css.id}>
                <path d="M122.69,359.23l2.07-2a8.84,8.84,0,0,0,1.5-1.8,3,3,0,0,0,.45-1.46,1.51,1.51,0,0,0-.43-1.14,1.64,1.64,0,0,0-1.16-.41,2.39,2.39,0,0,0-1.11.25,4.52,4.52,0,0,0-1.07.84l-.4-.66a4,4,0,0,1,1.21-1,3.35,3.35,0,0,1,1.46-.31,2.74,2.74,0,0,1,1.88.63,2.18,2.18,0,0,1,.71,1.71,3.52,3.52,0,0,1-.52,1.78,9.92,9.92,0,0,1-1.77,2.1L124.25,359v0h3.94v.84h-5.5Z" />
              </g>
              <path
                className={css.area}
                style={{ fill: this.state.colors[44] }}
                d="M160.51,383.87v30.28h-4.33v4a61.93,61.93,0,0,1-82-18.12l47.26-35.37v0l7.79.13Z"
              />
              <path
                id="light-6"
                data-name="light"
                className="cls-2"
                d="M128.57,364.64l-7.1,0c0,2.51,1,3.55,3.54,3.56S128.56,367.15,128.57,364.64Z"
              />
            </g>
          </svg>
        </div>
      </main>
    );
  }
}

export default withFirebase(withAppContext(Floorplan));
